<template>
  <div>
    <div class="flex__page">
      <div class="flex__page--inner">
        <p class="flex__page--icon"><font-awesome-icon icon="sad-tear" /></p>
        <h1>ネットワークにつながっていません。</h1>
        <p>TOKYOWAVEはオンラインでの利用のみとなっています。</p>
        <p>お手数をおかけしますが、端末のモバイル通信やWifiなどのオンにして、通信ができる状態で再度お試しください。</p>
        <p class="flex__page--btn">
          <span class="cm__button" @click="pageBack();">再度読み込む</span>
        </p>
        <bottomLogo />
      </div>
    </div>
  </div>
</template>

<script>
import bottomLogo from "@/components/atom/logo.vue";
import {Mixin} from '@/mixins/common'

export default {
  data() {
    return {
      title: 'ネットワークにつながっていません',
      description: 'TOKYOWAVEはオンラインでの利用のみとなっています。'
    };
  },
  components: {
    bottomLogo
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
    meta: function () {
      return [
        { property: 'og:title', content: this.title, id: 'og-title'},
        { property: 'og:description', content: this.description, id: 'og-desc' },
        { name: 'description', content: this.description, id: 'meta-desc'},
      ]
    },
  },  
  mixins:[Mixin],
}
</script>

<style scoped>
.cm__button{
  color: #FFF;
  font-size: .75rem;
  display: inline-flex;
  align-items: center;
  border: 1px solid #1ab3ff;
  border-radius: 8px;
  padding: 6px 8px;
  position: relative;
  white-space: nowrap;
  margin: 0 16px;
  z-index: 2;
  background-color: #1ab3ff;
}

</style>
