<template functional>
  <script type="application/ld+json">
  {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "{{props.item.name}}",
    "brand": {
      "@type": "Thing",
      "name": "{{props.item.brand_name_en}}"
    },
    "offers": {
      "@type": "AggregateOffer",
      "lowPrice": "{{props.item.min_price_num}}",
      "highPrice": "{{props.item.regular_price_num}}",
      "priceCurrency": "JPY",
      "offerCount": "{{props.count}}"
    }
  }
  </script>
</template>

<script>
export default {
  props: ['item', 'count'],
}
</script>
