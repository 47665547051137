<template>
  <div class="brand__link--btn">
    <span :class="{active: isStar}" class="item__detail__btn" @click="toggleStar"><font-awesome-icon icon="star" /></span>
    <span :class="{active: isHide}" class="item__detail__btn" @click="toggleHide"><font-awesome-icon icon="eye-slash" /></span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  props: ['brand_id'],
  data() {
    return {
      Hide: false,
      Star: false,
      id: '',
    }
  },
  created () {
  },
  moutend(){
  },
  computed: {
    ...mapGetters([
      'starBrands', 'hideBrands'
    ]),
    getId: function(){
      return this.brand_id
    },
    isStar: function(){
      this.setStar(this.getId)
      return this.Star
    },
    isHide: function(){
      this.setHide(this.getId)
      return this.Hide
    },
  },
  methods: {
    ...mapActions([
      "storeStars", 'storeHide'
    ]),
    setStar(id){
      this.Star = (this.starBrands.indexOf(id) >= 0)? true:false;
    },
    setHide(id){
      this.Hide = (this.hideBrands.indexOf(id) >= 0)? true:false;
    },
    toggleHide(){
      let mes = ''
      if(this.isHide === true){
        mes = '非表示リストから削除しました'
      }else{
        mes = '非表示リストに追加しました'
      }
      this.storeHide({list: 'Brands', target: this.brand_id})
      if(this.isStar === true) this.storeStars({list: 'Brands', target: this.brand_id})

      this.$toast(mes, {
                position: 'top',
                duration: 2000
            });
    },
    toggleStar(){
      let mes = ''
      if(this.isStar === true){
        mes = 'お気に入りから削除しました'
      }else{
        mes = 'お気に入りに追加しました'
      }
      this.storeStars({list: 'Brands', target: this.brand_id})
      if(this.isHide === true)  this.storeHide({list: 'Brands', target: this.brand_id})

      this.$toast(mes, {
                position: 'top',
                duration: 2000
            });
    },
  }
}
</script>
