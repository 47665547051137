import { mapGetters } from 'vuex';
// import firebase from 'firebase/app'

const _categories =  {
  tops: {id: 1, name: 'トップス' },
  outer: {id: 2, name: 'アウター' },
  onepiece: {id: 3, name: 'ワンピース' },
  bottoms: {id: 4, name: 'ボトムス' },
  shoes: {id: 5, name: 'シューズ' },
  bag: {id: 6, name: 'バッグ・ポーチ' },
  accessories: {id: 7, name: 'アクセサリー' },
  goods: {id: 8, name: '雑貨・小物' },
  innerclothes: {id: 9, name: 'インナーウェア' },
  swimwear: {id: 90, name: '水着' },
  yukata: {id: 91, name: '浴衣' },
  happybags: {id: 99, name: '福袋' },
};

const _id_categories =  {
  1: {code: 'tops', name: 'トップス' },
  2: {code: 'outer', name: 'アウター' },
  3: {code: 'onepiece', name: 'ワンピース' },
  4: {code: 'bottoms', name: 'ボトムス' },
  5: {code: 'shoes', name: 'シューズ' },
  6: {code: 'bag', name: 'バッグ・ポーチ' },
  7: {code: 'accessories', name: 'アクセサリー' },
  8: {code: 'goods', name: '雑貨・小物' },
  9: {code: 'innerclothes', name: 'インナーウェア' },
  90: {code: 'swimwear', name: '水着' },
  91: {code: 'yukata', name: '浴衣' },
  99: {code: 'happybags', name: '福袋' },
};

const categoryRouteName = 'CategoryListSearch'

const _categoriyMenus =  {
  1:{
    type: 'router-link',
    params: {name: categoryRouteName, params: {code: 'tops'}},
    label: 'トップス'
  },
  2:{
    type: 'router-link',
    params: {name: categoryRouteName, params: {code: 'outer'}},
    label: 'アウター'
  },
  3:{
    type: 'router-link',
    params: {name: categoryRouteName, params: {code: 'onepiece'}},
    label: 'ワンピース'
  },
  4:{
    type: 'router-link',
    params: {name: categoryRouteName, params: {code: 'bottoms'}},
    label: 'ボトムス'
  },
  5:{
    type: 'router-link',
    params: {name: categoryRouteName, params: {code: 'shoes'}},
    label: 'シューズ'
  },
  6:{
    type: 'router-link',
    params: {name: categoryRouteName, params: {code: 'bag'}},
    label: 'バッグ・ポーチ'
  },
  7:{
    type: 'router-link',
    params: {name: categoryRouteName, params: {code: 'accessories'}},
    label: 'アクセサリー'
  },
  8:{
    type: 'router-link',
    params: {name: categoryRouteName, params: {code: 'goods'}},
    label: '雑貨・小物'
  },
  9:{
    type: 'router-link',
    params: {name: categoryRouteName, params: {code: 'innerclothes'}},
    label: 'インナーウェア'
  },
  90:{
    type: 'router-link',
    params: {name: categoryRouteName, params: {code: 'swimwear'}},
    label: '水着'
  },
  91:{
    type: 'router-link',
    params: {name: categoryRouteName, params: {code: 'yukata'}},
    label: '浴衣'
  },
  99:{
    type: 'router-link',
    params: {name: categoryRouteName, params: {code: 'happybags'}},
    label: '福袋'
  },

};

export const Mixin =  {
  data () {
    return {
      // api: 'http://localhost:3000/v1',
      api: 'https://app.tokyowave.net/v1',
      categories: _categories,
      idCategories: _id_categories,
      categoriyMenus: _categoriyMenus,
    }
  },
  computed: {
    ...mapGetters([
      'hasHistory', 'getPageBack', 'searchOpen', 'isLogin', 'userData', 'Installable'
    ])
  },
  directives: {
    focus: {
      // ディレクティブ定義
      inserted: function (el) {
        el.classList.add('focus')
      }
    }
  },
  methods:{
    pageBack(){
      // console.log('mixin pageBack');
      this.$store.commit('setPageBack', true);
      this.$router.go(-1);
    },
    webShare(data) {
      console.log(navigator.share);
      if (navigator.share) {
        navigator.share(data)
      } else {
        if (this.onError) {
          this.onError('Web Share not supported');
        }
      }
    },
    searchOn(){
      this.$store.commit('setSearchOpen', true);
    },
    searchOff(){
      this.$store.commit('setSearchOpen', false);
      this.$store.commit('setSearchTabs', '1');
      document.activeElement.blur()
    },
    // loginHandler(){
    //   firebase.auth().onAuthStateChanged(user => {
    //     // console.log(user);
    //     if (user) {
    //       this.$store.commit('setLogin', true);
    //       const userData = {};
    //       userData.uid = user.uid
    //       userData.displayName = user.displayName
    //       this.$store.commit('setUserData', userData);
    //     } else {
    //       this.$store.commit('setLogin', false);
    //       this.$store.commit('setUserData', null);
    //     }
    //   });
    // },
    // logOut(){
    //   firebase.auth().signOut().then(()=>{
    //     this.$store.commit('setLogin', false);
    //     this.$store.commit('setUserData', null);
    //   })
    // },
    getRandamCode(){
      const len = 20;
      const str = "abcdefghijklmnopqrstuvwxyz0123456789";
      const strLen = str.length;
      let result = '';

      for (let i = 0; i < len; i++) {
        result += str[Math.floor(Math.random() * strLen)];
      }

      return result;
    }
  }
}
