<template>
  <div class="recobox">
    <h3 class="boxTitle">こちらのブランドもおすすめ</h3>
    <ul class="recobox">
      <li v-for="brand in lists" :key="brand.id">
        <router-link :to="{ name: 'BrandTop', params: {code: brand.code}}" class="recoboxItem">
          <span class="recoboxItemTxt">
            <span class="brandEn">{{brand.name_en}}</span>
            <span class="brandJa">{{brand.name_ja}}</span>
          </span>
          <span class="recoboxItemImg">
            <span v-for="img in brand.imgs" :key="img.img_url_min" class="recoboxItemImgInner"><img :src="img.img_url_min" width="100%"></span>
          </span>
          <span class="linkArrow"><font-awesome-icon icon="chevron-right" /></span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['lists'],
}

</script>

<style>
.recobox{
  list-style:none;
  padding-left: 0;
  margin: 0 0 16px;
}

.recobox li{
  border-bottom: 1px solid #EEE;
  background-color: #FFF;
}

.recoboxItem{
  display: flex;
  align-items: center;
  padding: 8px 12px;
}



.recoboxItemTxt{
  width: 100%;
}

.recoboxItemImg{
  display: flex;
  align-items: center;
  width: 160px;
  padding: 0 16px;
}

.recoboxItemImgInner{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.recoboxItemImg span img{
  max-width: 100%;
}
</style>
