<template functional>
  <router-link :to="'/brand/' + props.brand_code" v-scroll-to="'#app'" class="brand__link__box">
    <span class="brand__link__box--name__box">
      <span class="brandEn">{{props.brand_name_en}}</span>
      <span class="brandJa">{{props.brand_name_ja}}</span>
    </span>
    <span class="linkArrow"><font-awesome-icon icon="chevron-right" /></span>
  </router-link>
</template>

<script>

export default {
  props: ['brand_code', 'brand_name_en', 'brand_name_ja'],
}
</script>

<style scoped>
.brand__link__box{
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 8px;
  background-color: #FFF;
  border-bottom: 1px solid #F0F0F0;
  border-top: 1px solid #FAFAFA;
  width: 100%;
  z-index: 1;
  position: relative;
}

.brand__link__box--name__box{
  width: 100%;
  padding: 0 12px;
}
</style>
