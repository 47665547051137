<template>
  <SearchListBox v-if="isHome" :query="setApiQuery" :basePath="setBaseURI" :key="setFullPath" :brandCode="brandCode" />
  <div v-else class="gird" id="topNav">
    <Head v-bind:data="HeadSetting" />
    <section class="container">
      <SearchListBox :query="setApiQuery" :basePath="setBaseURI" :key="setFullPath" :brandCode="brandCode" />
    </section>
  </div>
</template>

<script>
import {Mixin} from '@/mixins/common'
import Head from "@/components/block/Head.vue";
import SearchListBox from "@/components/block/SearchListBox.vue";

import { mapGetters } from 'vuex';

export default {
  name: 'SeachList',
  data() {
    return {
      HeadSetting: {
        Left: 'backBtn',
        Right: 'searchOption',
        Center: 'search'
      },
      apiQuery: '',
      basePath: '',
      pageQuery: '',
      fullPath: '',
      queryWord: '',
      brandCode: null,
      categoryName: '',
      isHome: false
    }
  },
  mixins:[Mixin],
  components: {
    Head,
    SearchListBox,
  },
  computed: {
    ...mapGetters([
      'searchWord', 'hideItems'
    ]),
    getQuery: {
      get () {
        return this.apiQuery
      },
      set (value) {
      }
    },
    setApiQuery: function(){
      const r = this.$route
      // HOME
      if(r.name === 'home'){
        this.apiQuery = '/items/list/all/all/?'
      }

      // 単純検索
      if(r.name === 'Search'){
        if(r.query.key){
          this.apiQuery = '/search/?'
          this.apiQuery += Object.keys(r.query).map(function(key){ return key!=='page'? key+"="+ encodeURI(r.query[key]):''; }).join("&")
          this.queryWord = decodeURI(r.query.key)
        }
      }

      // カテゴリ検索
      if(r.name.match(/CategoryListSearch/g)){
        if(this.categories[r.params.code]){
          this.apiQuery = '/items/list/all/all/' + this.categories[r.params.code].id + '/?'

          if(r.params.sub_category_code) this.apiQuery += 'sub_category_code=' +  r.params.sub_category_code + '&'

          this.apiQuery += Object.keys(r.query).map(function(key){ return key!=='page'? key+"="+ encodeURI(r.query[key]):''; }).join("&")
          this.queryWord = this.categories[r.params.code].name
        }
      }

      if(r.name === 'BrandItems'){
        this.brandCode = r.params.code
        this.apiQuery = '/items/brands/' + r.params.code + '/?'
        this.apiQuery += Object.keys(r.query).map(function(key){ return key!=='page'? key+"="+ encodeURI(r.query[key]):''; }).join("&")
      }
      if(r.name === 'BrandItemsCategory'){
        this.brandCode = r.params.code
        if(this.categories[r.params.category]){
          const cat_id =
          this.apiQuery = '/items/brands/' + r.params.code + '/?category_id=' + this.categories[r.params.category].id + '&'
          this.apiQuery += Object.keys(r.query).map(function(key){ return key!=='page'? key+"="+ encodeURI(r.query[key]):''; }).join("&")
        }
      }

      // 非表示IDをクエリに追加する
      if(this.hideItems.length > 0){
        this.apiQuery += '&not_ids=' + this.hideItems.join(',')
      }

      // console.log(this.apiQuery);
      return this.apiQuery
    },
    setBaseURI: function(){
      const r = this.$route
      if(r.meta.search === true){
        this.basePath = this.$route.path + "?"
        this.basePath += Object.keys(r.query).map(function(key){ return key!=='page'? key+"="+ r.query[key]:''; }).join("&")
      }
      return this.basePath
    },
    setFullPath: function(){
      const r = this.$route
      if(r.meta.search === true){
        this.fullPath = this.$route.fullPath
      }
      return this.fullPath
    }
  },
  created () {
    // console.log('SeachList create');
    this.initialize(this.$route)
  },
  mounted () {
    // console.log('SeachList mount');
  },
  watch: {
    $route(to, from) {
      if(to.meta.search === true){
        this.initialize(to)
      }
    }
  },
  // beforeRouteUpdate(to, from, next) {
  //   console.log('SeachList before route change');
  //
  //   // Search同士のルートチェンジだったら初期化
  //   // console.log(to.meta.search);
  //   if(to.meta.search === true){
  //     this.initialize(to)
  //   }
  //   next();
  // },
  beforeDestroy(){
    // console.log('SeachList before Destroy');
  },
  methods: {
    initialize(route){
      // console.log('search query initialized');
      this.apiQuery = ''
      this.basePath = ''
      this.brandCode = null
      this.categoryName =　''

      if(route.name === 'home'){
        this.isHome = true
        this.HeadSetting = {
          Left: 'board',
          Right: 'mypage',
          Center: 'search'
        }
        this.$store.commit('setSearchWord', '')
      }else{
        this.isHome = false
        this.HeadSetting = {
          Left: 'backBtn',
          Right: 'searchOption',
          Center: 'search'
        }
      }

      // 検索キーワード
      if(route.query.key){
        this.$store.commit('setSearchWord', route.query.key)
      }else{
        this.$store.commit('setSearchWord', '')
      }

      // 検索履歴を作成
      if(route.name !== 'home'){
        this.setKeywordForHistory(route)
      }
    },
    setKeywordForHistory(route){
      const r = route
      //keyword
      let keywordArray = []

      if(this.searchWord !==''){
        keywordArray.push(decodeURI(this.searchWord))
      }

      //brand
      if(r.name.match(/BrandItems/g)){
        keywordArray.push(r.params.code)
      }

      // category
      if(r.name.match(/CategoryListSearch/g)){
        keywordArray.push(this.categories[r.params.code].name)
      }

      // category
      if(r.name.match(/BrandItemsCategory/g)){
        keywordArray.push(this.categories[r.params.category].name)
      }


      const history = {}

      history.code = this.getRandamCode()
      history.keyword = keywordArray.join()
      history.fullPath = route.fullPath
      // console.log(history);
      //
      // console.log('add history');
      this.$store.commit('addSearchWordHistory', history)
    },
  }
}
</script>

<style scoped>
</style>
