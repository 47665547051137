<template>
  <p @click="toggleStar()" :class="{active:isStar}" class="item-list__detail__star"><font-awesome-icon icon="star" /></p>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  name: 'ItemStar',
  props: ['itemId', 'title'],
  data() {
    return {
      Hide: false,
      Star: false,
      id: '',
    }
  },
  created () {
  },
  mounted () {
  },
  watch: {
    '$route' (to, from) {
      // console.log('star route change watch');
      // this.setStar(this.getId)
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  computed: {
    ...mapGetters([
      'starItems', 'hideItems'
    ]),
    getId: function(){
      return parseInt(this.itemId)
    },
    isStar: function(){
      this.setStar(this.getId)
      return this.Star
    },
    isHide: function(){
      this.setHide(this.getId)
      return this.Hide
    },
  },
  methods: {
    ...mapActions([
      "storeStars", 'storeHide', 'addHistory'
    ]),
    setStar(id){
      this.Star = (this.starItems.indexOf(id) >= 0)? true:false;
    },
    setHide(id){
      this.Hide = (this.hideItems.indexOf(id) >= 0)? true:false;
    },
    toggleStar(){
      this.storeStars({list: 'Items', target: this.itemId})
      if(this.isHide === true) this.storeHide({list: 'Items', target: this.itemId})


      if(this.isStar === true){
        const history = {}
        history.fullPath = '/items/' + this.itemId
        history.title = this.title
        history.type = 2
        // console.log(history);
        this.addHistory(history)
      }
    },
  }
}
</script>

<style scoped>

.item-list__detail__star{
  display: flex;
  padding: 12px;
  font-size: 1rem;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #FFF;
  opacity: .5;
  margin: 0;
  overflow: hidden;
}

.item-list__detail__star.active{
  color: #1AB3FF;
}

.item-list__detail__star:after{
  content: '';
  display: block;
  width: 98%;
  height: 98%;
  background-color: #EEE;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-radius: 50%;
  transition: all 0.3s ease;
  opacity: 0;
}

.item-list__detail__star:active:after{
  opacity: 1;
  width: 100%;
  height: 100%;
}

.item-list__detail__star.active{
  animation: bounce 400ms both cubic-bezier(0.165, 0.84, 0.44, 1);
}


@keyframes bounce {
  0% {
    transform:scale(1);
  }
  70% {
    transform:scale(1.3);
  }
  100% {
    transform:scale(1);
  }
}


</style>
