<template>
  <transition name="slide">
    <div class="" v-if="parent">
      <div v-show="isLoading" class="fullLoading">
        <loading :size="2" :icon="'spinner'" />
      </div>
      <ul v-show="isLoaded" class="holdList">
        <li v-for="data in lists">
          <p v-on:click="toggleChild(data.label)">
            <span class="holdListTxt">{{data.label}}</span>
            <font-awesome-icon icon="chevron-right" />
          </p>
        </li>
      </ul>
    </div>
    <div class="" v-if="child">
      <p class="back__link fixedtop" v-on:click="toggleChild()">
        <span class=".back__link box">
          <span class="back__arrow"><font-awesome-icon icon="chevron-right" /></span>
          <span class="name">戻る</span>
        </span>
      </p>
      <div v-show="childLoading" class="fullLoading">
        <loading :size="2" :icon="'spinner'" />
      </div>
      <ul v-show="childLoaded" class="holdList with__btn">
        <li v-for="data in childLists" @click="searchOff">
          <router-link :to="{name: 'BrandTop', params: {code: data.code}}">
            <span class="holdListTxt">
              <span class="brandEn">{{data.name_en}}</span>
              <span class="brandJa">{{data.name_ja}}</span>
            </span>
            <font-awesome-icon icon="chevron-right" />
          </router-link>
        </li>
      </ul>
    </div>
  </transition>
</template>
<script>
import {Mixin} from '@/mixins/common'
import loading from "@/components/atom/loading.vue";
import axios from 'axios';

export default {
  data() {
    return {
      lists: {},
      isLoading: true,
      isLoaded: false,
      parent: true,
      child: false,
      childLoading: true,
      childLoaded: false,
      childLists: {}
    }
  },
  components: {
    loading
  },
  created(){

  },
  computed: {
  },
  mixins:[Mixin],
  methods: {
    async getData(){
      const url = this.api + '/brands/indexes/'
      console.log(url);

      await axios.get(url)
      .then((res) => {
        this.lists = res.data
        this.isLoading = false
        this.isLoaded = true
      })
      .catch((e) =>{
        console.log(e);
        console.log('item lists not found')
      })
    },
    async getList(alpha){
      const url = this.api + '/brands/lists/?alpha=' + alpha
      console.log(url);

      await axios.get(url)
      .then((res) => {
        this.childLists = res.data
        this.childLoading = false
        this.childLoaded = true
      })
      .catch((e) =>{
        console.log(e);
        console.log('item lists not found')
      })
    },
    toggleChild: function (letter){
      this.parent = this.parent? false: true;
      this.child = this.child? false: true;
      if(this.child === true){
        this.getList(letter)
      }else{
        this.childLists = {}
        this.childLoading = true
        this.childLoaded = false
      }
    }
  },
}
</script>

<style scoped>
.holdList.with__btn{
  position: relative;
  padding: 56px 0 88px;
}
.back__link.fixedtop{
  width: 100%;
  position: fixed;
  top: 88px;
  z-index: 10;
  border-bottom: 1px solid #EEE;
}
</style>
