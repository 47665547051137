<template functional>
  <ul class="ecList">
    <li v-for="(ec, index) in props.eclist" :key="index">
      <div class="ecListInner">
        <p class="ecFavBox"><span class="ecFav"><img :src="ec.ec_store.icon" width="44px" :alt="ec.ec_store.name"></span></p>
        <div class="ec__site__name">
          <span v-if="ec.ec_store.official === 1" class="label official">公式サイト</span>
          <span class="ec__site__name--txt">{{ec.ec_store.name}}</span>
        </div>
        <div class="ecPriceBoxInner">
          <p><span class="price">{{ec.price}}</span><span class="fee">送料：{{ec.shippingfee}}</span></p>
          <p></p>
        </div>
        <p class="linkBtn" v-html="ec.link"></p>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props:['eclist']
}
</script>

<style>

.linkBtn{
  min-width: 90px;
  max-width: 90px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkBtn a{
  background-color: #FF3300;
  border-radius: 6px;
  font-size: .8rem;
  font-weight: bold;
  padding: 6px 12px;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #FFF;
}

.ec__site__name{
  font-size: .7rem;
  min-width: 110px;
  max-width: 110px;
  font-weight: bold;
  color: #666;
  text-align: left;
  padding: 0 4px;
  word-break: break-all;
}

.ec__site__name--txt{
  display: block;
  padding-top: 4px;
}

.ecList .price{
  font-size: 1rem;
  line-height: 1.6rem;
}


</style>
