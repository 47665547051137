<template>
  <div class="gird">
    <Head v-bind:data="HeadSetting" />
    <section class="container">
      <div class="fixInnerTop">
        <scrollTab :pageNav="pageNav" />
      </div>
      <transition>
        <router-view/>
      </transition>
    </section>
  </div>
</template>

<script>
import Head from "@/components/block/Head.vue";
import PageNav from "@/components/block/PageNav.vue";
import scrollTab from "@/components/parts/scrollTab.vue";
import { mapGetters } from 'vuex';

export default {
  name: "TopItemList",
  data() {
    return {
      HeadSetting: {
        Left: 'board',
        Right: 'mypage',
        Center: 'search'
      },
      pageNav: {
        lists: [],
        id: 'topNav'
      }
    };
  },
  components: {
    Head,
    PageNav,
    scrollTab
  },
  created () {
    this.setPageNav(this.$route)
  },
  computed: {
    getNav: function(){
      return this.pageNav
    },
    ...mapGetters([
      'searchWordHistory'
    ]),
  },
  watch: {
    $route(to, from) {
      // console.log('top frame watch');
      // this.setPageNav(to)
      // if(to.meta.search === true){
      //   // this.initialize(to)
      // }
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.setPageNav(to)
    next();
  },
  methods: {
    setPageNav(route){
      let nav = [
        {
          route: {name: 'HomeViewed'},
          label: '閲覧履歴',
          fullPath: '/viewed'
        },
        {
          route: {name: 'HomeStarred'},
          label: 'お気に入り',
          fullPath: '/starred'
        },
        {
          route: {name: 'home'},
          label: 'おすすめ',
          fullPath: '/'
        },
      ]

      for(let i in this.searchWordHistory){
        if(this.searchWordHistory[i].topview && this.searchWordHistory[i].topview === true){
          const tmp = {
            route: {name: 'HomeSaved', query:{sid: this.searchWordHistory[i].code}},
            label: this.searchWordHistory[i].keyword,
            fullPath: '/saved?sid=' + this.searchWordHistory[i].code
          }
          nav.push(tmp)
        }
      }

      nav = nav.filter(function(item) {
        let routePath = ''
        if(route.fullPath.match(/standalone/g) !== null){
          routePath = '/'
        }else{
          routePath = route.fullPath
        }

        if(route.fullPath.match(/page/g) !== null){
          routePath = '/'
        }
        
        if(item.fullPath === routePath){
          item.checked = true;
        }
        return item
      })

      this.pageNav.lists = nav
    }
  }
}
</script>
