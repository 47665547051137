<template functional>
  <p class="bottom-logo">
    <img src="/img/fullicon-small.png" width="100" height="59" alt='TOKYOWAVEのロゴ' />
  </p>
</template>

<script>
export default {
  created () {
    console.log('parts create');
  },
  mounted () {
    console.log('parts mount');
  },
  watch: {
    '$route' (to, from) {

      console.log('Parts route change watch');
    }
  },
}
</script>

<style scoped>
.bottom-logo{
  display: flex;
  align-items: center;
  margin: 64px auto;
  justify-content: center;
}

.bottom-logo img{
  opacity: .3;
}
</style>
