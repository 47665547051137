<template>
  <div class="pagerCon">
    <input class="hide" id="pageList" type="checkbox" />
    <label class="pageListOpen" for="pageList"><font-awesome-icon icon="chevron-right" /></label>
    <div class="pageList">
      <ul class="pageListUl">
        <li v-for="nowPage in showNums" :key="nowPage">
          <span class="active num" v-if="page == nowPage">{{nowPage}}</span>
          <router-link v-else :to="{path: bathPath + '&page=' + nowPage}" class="num">
            {{nowPage}}
          </router-link>
        </li>
      </ul>
    </div>
    <label class="pageNums" for="pageList">
      <p class="numBox">{{page}} / {{pageTotal}} ページ</p>
    </label>
  </div>
</template>

<script>

export default {
  props: ['pageTotal', 'page', 'pageMax', 'bathPath'],
  mounted () {
  },
  computed: {
    showNums: function() {
      let pages = []
      let start = parseInt(this.page) - (parseInt(this.pageMax - 1)/2);
      let end = parseInt(this.page) + (parseInt(this.pageMax - 1)/2);

      if (start <= 0) {
        end = this.pageMax
        start = 1
      }

      if(this.pageTotal <= end){
        end = this.pageTotal
        start = parseInt(this.pageTotal) - parseInt(this.pageMax - 1)
      }
      //
      for(let i =start; i<=end; i++){
        pages.push(i)
      }
      return pages
    },
  },
  methods: {
  }
}
</script>


<style scoped>

.pagerBox{
  transition: all 0.8s ease;
  position: fixed;
  bottom: 40px;
  right: 8px;
}

.pagerBox.hidden{
  bottom: -80px;
}


.pagerCon{
  background: rgba(0,0,0,.7);
  padding: 6px 12px 6px 8px;
  border-radius: 18px;
  display: flex;
  align-items: center;
}

.pageNums{
  color: #FFF;
  font-size: 12px;
  line-height: .9rem;
  display: flex;
  align-items: center;
}

.pageListOpen{
  transform: rotate(180deg);
  font-size: 1.1rem;
  display: inline-block;
  padding: 0 4px;
  color: #FFF;
  transition: all 0.5s ease;
  transform-origin:center center;
}

.numBox{
  margin: 0;
}

#pageList:checked + .pageListOpen{
  transform: rotate(0deg);
}

#pageList:checked ~ .pageList{
  max-width: 400px;
}

.pageList{
  max-width: 0;
  overflow: hidden;
  transition: all 0.5s ease;
}

.pageListUl{
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 8px 0 -4px;
}

.pageListUl li{
  margin: 0 4px;
}

.pageList .num{
  color: #FFF;
  font-size: 11px;
  line-height: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  padding: 4px;
  background-color: #777;
  text-align: center;
  border-radius: 50%;
}

.num.active{
  background-color: #FFF;
  color: #333;
}
</style>
