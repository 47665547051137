<template>
  <div class="gird">
    <Head v-bind:data="HeadSetting" />
    <section class="container">
      <div class="itemBox">
        <h2 class="boxTitle">保存した条件</h2>
        <ul class="menuList">
          <li v-for="(data, index) in history" :key="index">
            <p class="menuListInner">
              <span class="menuListTxt small">{{data.keyword}}</span>
              <!-- <span class="menuListOnOff"><font-awesome-icon icon="comment-alt" /></span> -->
              <!-- <span class="menuListOnOff"><font-awesome-icon icon="stream" /></span> -->
              <span class="menuListOnOff" @click="removeSearchWordHistory(data.fullPath)"><font-awesome-icon icon="times-circle" /></span>
            </p>
          </li>
        </ul>
        <p v-if="!isLogin" class="noticeTxt">ログインしていない場合、「保存した条件」は端末やブラウザのリセットと共に消える可能性があります。</p>
      </div>
      <div class="itemBox">
        <h2 class="boxTitle">その他</h2>
        <ul class="menuList">
          <li>
            <router-link :to="{name: 'About'}" class="menuListInner">
              <span class="menuListTxt">TOKYOWAVEについて</span>
              <span class="linkArrow"><font-awesome-icon icon="chevron-right" /></span>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'Privacy'}" class="menuListInner">
              <span class="menuListTxt">プライバシーポリシー</span>
              <span class="linkArrow"><font-awesome-icon icon="chevron-right" /></span>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'Version'}" class="menuListInner">
              <span class="menuListTxt">バージョン情報</span>
              <span class="linkArrow"><font-awesome-icon icon="chevron-right" /></span>
            </router-link>
          </li>
        </ul>
      </div>
      <p class="logOutBox" v-if="isLogin" @click="logOut">
        <span class="logOutIcon"><font-awesome-icon icon="sign-out-alt" /></span>
        <span class="logOutTxt">ログアウト</span>
      </p>
      <div v-if="!pwaInstalled && isInstallable" class="installBanner" :class="{active: bnrOn}">
        <p class="installBannerTxt">
          <span class="installBannerIcon"><img src="/img/icons/android-chrome-192x192.png"/></span>
          <span class="installBannerCatch">TOKYOWAVEをインストールして、もっとファッションを楽しもう！</span>
        </p>
        <ul class="choiceBox">
          <li @click="installLater"><span class="choiceBtn">あとで</span></li>
          <li @click="installBnr"><span class="choiceBtn active">インストール</span></li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import Head from "@/components/block/Head.vue";
import SwitchBtn from "@/components/atom/switch.vue";
import {Mixin} from '@/mixins/common'
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      HeadSetting: {
        Left: '/',
        Right: '',
        Center: 'ゲスト さん'
      },
      bnrOn: true,
      deferredPrompt: '',
      title: 'マイページ',
      description: 'TOKYOWAVEのマイページ。保存した検索条件の管理など、アプリの管理ができます。',
    };
  },
  mixins:[Mixin],
  components: {
    Head,
    SwitchBtn,
  },
  created(){
    console.log('mypage created');
    this.deferredPrompt = ''
    // PWA
    this.setInstallPpt()
    this.handlePWA()
  },
  mounted () {
    // console.log(this.isLogin);
    if(this.isLogin === true){
      this.HeadSetting.Center = this.userData.displayName + " さん";
    }
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
    meta: function () {
      return [
        { property: 'og:title', content: this.title, id: 'og-title'},
        { property: 'og:description', content: this.description, id: 'og-desc' },
        { name: 'description', content: this.description, id: 'meta-desc'},
      ]
    },
  },
  computed: {
    ...mapGetters([
      'searchWordHistory', 'pwaInstalled'
    ]),
    history: {
      get () {
        const list = this.searchWordHistory.filter(function(item) {
          if(item.saved) return item
        });
        return list
      }
    },
    isInstallable: function(){
      return true;
      if(this.pwaInstalled === false && this.deferredPrompt !==''){
        return true;
      }else{
        return false;
      }
    }
  },
  methods: {
    ...mapActions([
      "removeSearchWordHistory", 'setSearchWordTopMenu'
    ]),
    setInstallPpt(){
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        this.deferredPrompt = e;
      });
    },
    handlePWA(){
      window.addEventListener('appinstalled', (evt) => {
        this.pwaInstalled = true
      });
    },
    installBnr(){
      this.deferredPrompt.prompt();
      // // PWAボタン
      // let deferredPrompt;
      //
      // window.addEventListener('beforeinstallprompt', (e) => {
      //   // Prevent Chrome 67 and earlier from automatically showing the prompt
      //   e.preventDefault();
      //   // Stash the event so it can be triggered later.
      //   deferredPrompt = e;
      //
      //   console.log(e);
      //   deferredPrompt.prompt();
      // });
    },
    getUserType() {
      var ua = [
        "iPod",
        "iPad",
        "iPhone",
        "Android"
      ]

      for (var i = 0; i < ua.length; i++) {
        if (navigator.userAgent.indexOf(ua[i]) > 0) {
          return true
        }
      }
      return "Other"
    },
    refreshSW(){
      window.location.reload(true);
    },
    installLater(){
      this.bnrOn = false
    }
  }
}
</script>

<style scoped>
.container{
  background-color: #EEE;
  padding-bottom: 160px
}

.loginBtnInner{
  text-align: center;
}

.loginBtn{
  width: 200px;
  display:flex;
  justify-content: center;
  border: 2px solid #1AB3FF;
  border-radius: 6px;
  padding: 8px 12px;
  margin: 0 auto;

}

.loginTxt{
  width: 120px;
  display: block;
  color: #1AB3FF;
  margin-right: 12px;
  text-align: center;
}

.loginIcon{
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  font-size: 1rem;
  border: 1px solid #EEE;
  border-radius: 50%;
}

.facebook{
  color: #305097;
}

.google{
  color: #db4a39;
}

.twitter{
  color: #00aced;
}


.boxTitle{
  color: #999;
}

.noticeTxt{
  margin: 4px 12px;
}

.menuList{
  list-style: none;
  padding-left: 0;
  margin: 0;
  font-size: .9rem;
}

.menuList li{
  border-bottom: 1px solid #EEE;
}

.menuList li:last-child{
  border-bottom: 0;
}

.menuListInner{
  display: flex;
  padding: 8px 8px 8px 16px;
  align-items: center;
  background-color: #FFF;
  margin: 0;
  height: 48px;
}

.menuListTxt{
  display: block;
  width: 100%;
}

.menuListTxt.small{
  font-size: .8rem;
}

.menuListOnOff{
  display: block;
  padding: 6px;
  margin-left: 8px;
  color: #AAA;
}

.installBanner{
  padding: 24px 24px 18px;
  background-color: #CCC;
  color: #FFF;
  position: fixed;
  bottom: -200px;
  transition: all .6s ease;
}

.installBanner.active{
  bottom: 0;
}

.installBannerTxt{
  font-weight: bold;
  display: flex;
  margin: 0;
}

.installBannerIcon{
  width: 48px;
  height: 48px;
  display: block;
  margin-right: 6px;
}

.installBannerIcon img{
  width: 48px;
  height: 48px;
}

.installBannerCatch{
  display: block;
  width: 100%;
  padding-left: 16px;
}

.choiceBox{
  list-style: none;
  padding-left: 0;
  text-align: right;
  margin: 16px 0 0;
}

.choiceBox li{
  display: inline-block;
  font-size: .9rem;
  margin-left: 8px;
}

.choiceBtn{
  display: block;
  padding: 4px 12px;
  border-radius: 20px;
  user-select: none;
}

.choiceBtn.active{
  background-color: #FFF;
  color: #FF3300;
  font-weight: bold;
}

.choiceBox li:active{
  opacity: .8;
}

.loginBox{
  background-color: #FFF;
  text-align: center;
  padding: 24px;
}

.logOutBox{
  display: block;
  text-align: center;
  padding: 12px;
  margin: 24px auto;
}

.logOutIcon{
  display: block;
  font-size: 1.4rem;
  color: #999;
}

.logOutTxt{
  display: block;
  font-size:.7rem;
  color: #999;
}
</style>
