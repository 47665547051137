<template>
  <div class="item__detail--btn__box">
    <span :class="{active: isStar}" class="item__detail__btn" @click="toggleStar"><font-awesome-icon icon="star" /></span>
    <span :class="{active: isHide}" class="item__detail__btn" @click="toggleBrandHide"><font-awesome-icon icon="eye-slash" /></span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  props: ['item_id', 'title'],
  data() {
    return {
      Hide: false,
      Star: false,
      id: '',
    }
  },
  created () {
  },
  moutend(){
  },
  computed: {
    ...mapGetters([
      'starItems', 'hideItems'
    ]),
    getId: function(){
      return parseInt(this.item_id)
    },
    isStar: function(){
      this.setStar(this.getId)
      return this.Star
    },
    isHide: function(){
      this.setHide(this.getId)
      return this.Hide
    },
  },
  methods: {
    ...mapActions([
      "storeStars", 'storeHide', 'addHistory'
    ]),
    setStar(id){
      this.Star = (this.starItems.indexOf(id) >= 0)? true:false;
    },
    setHide(id){
      this.Hide = (this.hideItems.indexOf(id) >= 0)? true:false;
    },
    toggleBrandHide(){
      let mes = ''
      if(this.isHide === true){
        mes = '非表示リストから削除しました'
      }else{
        mes = '非表示リストに追加しました'
      }
      this.storeHide({list: 'Items', target: this.item_id})
      if(this.isStar === true) this.storeStars({list: 'Items', target: this.item_id})

      this.$toast(mes, {
                position: 'top',
                duration: 2000
            });
    },
    toggleStar(){
      let mes = ''

      if(this.isStar === true){
        mes = 'お気に入りから削除しました'
      }else{
        mes = 'お気に入りに追加しました'

        const history = {}
        history.fullPath = '/items/' + this.item_id
        history.title = this.title
        history.type = 2
        if(this.title !== '') this.addHistory(history)
      }
      this.storeStars({list: 'Items', target: this.item_id})
      if(this.isHide === true) this.storeHide({list: 'Items', target: this.item_id})

      this.$toast(mes, {
                position: 'top',
                duration: 2000
            });
    },
  }
}
</script>
