<template>
  <div>
    <div class="flex__page">
      <div class="flex__page--inner">
        <p class="t-center">
          <img src="https://cdn.tokyowave.net/img/static/title.png" alt="TOKYOWAVEのアイコン">
        </p>
        <h1>メンテナンス中です</h1>
        <p>現在システムメンテナンスを行なっています。大変ご迷惑をおかけしまして申し訳ございません。</p>
        <p>メンテナンス終了後に再度ページを読み込んでいただきますようお願いいたします。</p>
        <h2>メンテナンス予定</h2>
        <dl class="dl__table">
          <dt>開始</dt>
          <dd>{{schedule.start}}</dd>
          <dt>終了</dt>
          <dd>{{schedule.end}}</dd>
        </dl>
        <p class="noticeTxt">※メンテナンス作業の進行によって前後する場合がございます。ご了承ください。</p>
        <div v-if="history.length > 0">
          <h2>メンテナンス進行状況</h2>
          <ul>
            <li v-for="(data, index) of history" :key="index">
              <p class="notice__box">
                <span class="notice__box--date">{{data.date}}</span>
                <span class="notice__box--txt">{{data.text}}</span>
              </p>
            </li>
          </ul>
        </div>
        <p class="flex__page--btn">
          <span class="cm__button" @click="pageBack();refreshSW()">再度読み込む</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {Mixin} from '@/mixins/common'

export default {
  data() {
    return {
      schedule:{},
      history:[],
    }
  },
  mixins:[Mixin],
  created () {
    this.getInfo()
  },
  methods: {
    getInfo(){
      const url = this.api + '/status.json'

      axios.get(url)
      .then((res) => {
        if(res.data.status == 200){
          this.$router.push({name: 'home'})
        }else{
          this.schedule = res.data.schedule
          this.history = res.data.history
        }
      })
      .catch((e) =>{
        this.$router.push({name: 'Maintenance'})
      })
    },
    refreshSW(){
      window.location.reload(true);
    }
  }
}
</script>

<style scoped>
.notice__box{
  display: flex;
  align-items: center;
}

.notice__box--date{
  color: #666;
  width: 120px;
  font-weight: bold;
}


.notice__box--txt{
  width: 100%;
  padding-left: 24px;
}

.dl__table{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.dl__table dt,
.dl__table dd
{
  display: inline-block;
  margin: 0;
}

.dl__table dt{
  font-weight: bold;
  color: #999;
}

.dl__table dd{
  font-size: .95rem;
  padding: 0px 24px 0 12px;;
}

.cm__button{
  color: #FFF;
  font-size: .75rem;
  display: inline-flex;
  align-items: center;
  border: 1px solid #1ab3ff;
  border-radius: 8px;
  padding: 6px 8px;
  position: relative;
  white-space: nowrap;
  margin: 0 16px;
  z-index: 2;
  background-color: #1ab3ff;
}

</style>
