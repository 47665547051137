<template>
  <div id="app" ontouchstart="" v-bind:class="{ appview: isSA}">
    <main id="top">
    <!-- <main v-touch:swipe.left="swipeLeftHandler" v-touch:swipe.right="swipeRightHandler" id="top"> -->
      <transition :name="transitionName" v-on:before-leave="debug" v-on:before-enter="toTop" :mode="tranMode">
        <keep-alive :include="/List/" :max="20">
          <router-view>
          </router-view>
        </keep-alive>
      </transition>
    </main>
    <a v-if="fromHome" @click="pageBack()" v-bind:class="{show: notHome}" class="homeBtn">
      <span class="homeBtnInner"><font-awesome-icon icon="home" /></span>
    </a>
    <router-link v-else v-bind:class="{show: notHome}" :to="{name: 'home'}" class="homeBtn">
      <span class="homeBtnInner"><font-awesome-icon icon="home" /></span>
    </router-link>
    <input class="hide" type="checkbox" id="topbnript" v-model="topBanner" />
    <topBanner :data="topBannerData" />
    <modalAlert v-if="modalOn">
      <div class="modal-box__block__inner">
        <p class="modal__title">アップデートをお願いします</p>
        <p class="flex__center__box center">
          <label for="viewtop">バージョン0.10.0がリリースされました。アップデートは数秒で完了します。</label>
        </p>
      </div>
      <div class="modal-box__block__buttons">
        <div class="modal-box__block__buttons__btn cancel" @click="toggleModal(false)">
          あとで
        </div>
        <div class="modal-box__block__buttons__btn" @click="doUpdate">
          アップデート
        </div>
      </div>
    </modalAlert>
    <jsonWebsite />
    <jsonOrganization />
  </div>
</template>
<script>
import {Mixin} from '@/mixins/common'
import { mapGetters, mapActions } from 'vuex';
import topBanner from "@/components/block/topBanner.vue";
import jsonWebsite from "@/components/parts/seo/website.vue";
import jsonOrganization from "@/components/parts/seo/organization.vue";
import axios from 'axios';
import modalAlert from "@/components/parts/modalAlert.vue";

export default {
  name: "App",
  components: {
    topBanner,
    jsonWebsite,
    jsonOrganization,
    modalAlert
  },
  data() {
    return {
      transitionName: "",
      swipeLeftTo:'mypage',
      swipeRightTo:'board',
      notHome: false,
      topBanner: false,
      toTopFlag: false,
      tranMode: 'in-out',
      fromHome: false,
      starList: ['Items', 'Brands'],
      hideList: ['Items', 'Brands'],
      topBannerData: {},
      modalOn: false,
      stateList: ['gMesFlag']
    };
  },
  created () {
    // console.log('App created');
    this.validOnline()
    this.chechMaintenance()
    this.restoreStar(this.starList)
    this.restoreHide(this.hideList)
    this.restoreHistory()
    this.restoreSearchWordHistory()
    this.restoreState(this.stateList)
  },
  mixins:[Mixin],
  mounted () {
    // console.log('App mounted');
    // アプリ
    if (window.matchMedia('(display-mode: standalone)').matches === true) {
      this.$store.commit('setStandalone', true)
    }
    // safari
    if (window.navigator.standalone === true) {
      this.$store.commit('setStandalone', true)
    }
    // 速報バナー
    // this.managebanner()
  },
  computed: {
    ...mapGetters([
      'isSA', 'getPageBack', 'Installable','starItems', 'viewedSale', 'gMesFlag'
    ])
  },
  watch: {
    $route(to, from) {
      // ネットワーク監視
      this.validOnline()
      this.chechMaintenance()
      // 初期化
      // console.log('App watch RouteChange');
      // if(to.name !=="Search"){
      //   this.$store.commit('setSearchWord', '')
      // }

      // Gバナー
      // this.managebanner()

      this.swipeLeftTo = ''
      this.swipeRightTo = ''

      if(to.meta.home){
        this.notHome = false;
      }else{
        this.notHome = true;
      }

      // homeから来たかを判定
      if(from.name === 'home'){
        this.fromHome = true
      }else{
        this.fromHome = false
      }

      if(to.meta.savePosi){
        this.toTopFlag = false
      }else{
        this.toTopFlag = true
      }

      let traname = from.meta.page + "-" + to.meta.page
      // console.log(traname);
      // console.log(traname)
      if (traname == 'main-right'){
        this.transitionName = "to-right"
        this.tranMode = 'out-in'
      }else if(traname == 'right-main'){
        this.transitionName = "to-left"
        this.tranMode = 'in-out'
      }else if(traname == 'main-left'){
        this.transitionName = "toboard"
        this.tranMode = 'out-in'
        // this.tranMode = 'in-out'
      }else if(traname == 'left-main'){
        this.transitionName = "to-right"
        this.tranMode = 'out-in'
      }else if(traname == 'right-bottom'){
        this.transitionName = "to-bottom"
      }else if(traname == 'bottom-right'){
        this.transitionName = "from-bottom"
      }else if(traname == 'main-push'){
        // this.transitionName = ""
        this.transitionName = "to-right"
        // this.swipeLeftTo = ''
        // this.swipeRightTo = ''
        this.tranMode = 'out-in'
      }else if(traname == 'push-main'){
        this.$store.commit('setPageBack', false)
        this.transitionName = "to-left"
        this.tranMode = 'in-out'
      }else if(traname == 'push-push'){
        if(this.getPageBack === true){
          this.transitionName = "to-left"
          this.tranMode = 'out-in'
          this.$store.commit('setPageBack', false)
        }else{
          this.transitionName = "to-right"
          this.tranMode = 'out-in'
        }
        this.swipeLeftTo = ''
        this.swipeRightTo = ''
      }else{
        this.transitionName = '';
        this.swipeLeftTo = ''
        this.swipeRightTo = ''
      }
      // console.log(this.tranMode);
      // console.log(this.transitionName);
    }
  },
  methods: {
    ...mapActions([
      "restoreStar", 'restoreHide', 'addViewedSale', 'restoreHistory', 'restoreSearchWordHistory', 'setgMesFlag', 'restoreState'
    ]),
    doUpdate(){
      this.modalOn = false
      window.location.reload(true);
    },
    toggleModal(valid){
      if(valid){
        this.modalOn = valid
      }else{
        this.modalOn = (this.modalOn)? false: true;
      }
    },
    validOnline(){
      const valid = navigator.onLine;
      if(valid === false){
        this.$router.push({name: 'Offline'})
      }
    },
    chechMaintenance(){
      if(navigator.onLine === true){
        const url = this.api + '/status.json'
        // console.log('check maintenece');
        axios.get(url)
        .then((res) => {
          if(res.data.status !== 200){
            this.$router.push({name: 'Maintenance'})
          }
        })
        .catch((e) =>{
          this.$router.push({name: 'Maintenance'})
        })
      }
    },
    toTop(){
      // console.log(this.$router.meta);
      if(this.toTopFlag === true){
        window.scrollTo(0,0)
      }
    },
    async managebanner(){
      let url = this.api + '/events/pickup?'

      if(this.viewedSale.length > 0){
        url += 'ng_ids=' + this.viewedSale.join(',')
      }

      await axios.get(url)
      .then((res) => {
        this.topBannerData = res.data
      })
      .catch((e) =>{
      })
      // セールデータがあったら
      if(Object.keys(this.topBannerData).length > 0){
        // console.log('バナーデータがありました');
        this.addViewedSale(this.topBannerData.id)
        setTimeout(
          function() {
            this.topBanner = true
            // console.log('バナーおん');
            // console.log(this.topBanner);
            setTimeout(
              function() {
                this.topBanner = false
                // console.log('バナーOFF');
                // console.log(this.topBanner);
              }.bind(this),
              4000
            );
          }.bind(this),
          5000
        );
      }
    },
    pwaHandler(){
      window.addEventListener('beforeinstallprompt', (evt) => {
        evt.preventDefault();
        this.$store.commit('setInstallable', true)

        // 後からプロンプトを表示出来るようにする
        this.deferredPrompt = evt;
      });

    },
    debug() {
      //debugger
    },
    message() {
    },
  }
};
</script>

<style>
* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Noto Serif JP','Arial',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,'ＭＳ ゴシック',sans-serif;
  box-sizing: border-box;
}

ul{
  list-style: none;
  padding-left: 0;
}

#app {
  display: grid;
  /* grid-template-rows: min-content; */
  min-height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.gheadBox{
  z-index: 9999;
}

.ghead {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #FFF;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9000;
  height: 46px;
  padding: 3px;
  transition: all .6s ease;
  /* box-shadow: 0 1px 6px rgba(0,0,0,0.1) */
}



.ghead.opacity {
  background-color: rgba(255,255,255,0);
}

.ghead.opacity.focused {
  background-color: rgba(255,255,255,1);
}

.ghead p{
  width: 44px;
}


a {
  text-decoration: none;
  color: #333;
}

a.router-link-exact-active {
  border-color: inherit;
}

main {
  height: 100%;
  display: grid;
  grid-template: "main";
  flex: 1;
  background-color: #FAFAFA;
  position: relative;
  z-index: 0;
  overflow-x: hidden;
}

main > * {
  grid-area: main; /* Transition: make sections overlap on same cell */
  background-color: white;
  position: relative;
}

main > :first-child {
  z-index: 1; /* Prevent flickering on first frame when transition classes not added yet */
}


.gird{
  display: grid;
  grid-template-rows: min-content;
  height: 100%;
}

.container {
  height: 100%;
  /* -webkit-overflow-scrolling: touch; */
  overflow-y: scroll;
  padding-top: 46px;
  background-color: #FCFCFC;
  /* box-shadow:-3px 0px 7px -3px #CCC; */
}

.container.fixed{
  position: fixed;
}

.dummy{
  width: 100%;
  height: 100%;
  display: block;
  background-color: #DDD;
  border-radius: 6px;
}

.dummy.light{
  background-color: #EEE;
}

.btn-inner{
  width: 20px;
  height: 20px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.btn-inner:after{
  content: '';
  height: 200%;
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  z-index: -1;
  background-color: #CCC;
  border-radius: 50%;
  opacity: 0;
  /* transition: all 200ms ease 0s; */
}

.btn:active .btn-inner{
  color: #999;
}

.btn:active .btn-inner:after{
  opacity: .8
}


.back__arrow{
  display: inline-block;
  transform:rotate(180deg);
  padding-left: 16px;
}

.back__link{
  margin: 0;
}

.back__link a, .back__link .box{
  background-color: #FFF;
  padding: 16px 24px;
  display: block;
}

.label{
  font-size: .6rem;
  color: #FFF;
  padding: 2px 4px;
  border-radius: 6px;
}

.label.mr{
  margin-right: 8px;
}

.label.official{
  background-color: #32CD32;
}

.label.pr{
  background-color: #1AB3FF;
}

.linkArrow{
  /* position: absolute; */
  right: 16px;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  color: #333;
}

.del{
  text-decoration: line-through;
}

.ecList{
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.ecListInner{
  display: flex;
  align-items: center;
}

.ecListInner.topbanner{
  display: block;
  padding-left: 12px;
}

.ecFavBox{
  min-width: 48px;
  max-width: 48px;
  margin: 8px;
  height: 48px;
  display: flex;
  align-items:center;
}

.ecFav{
  border-radius: 50%;
  border: 1px solid #EEE;
  min-width: 45px;
  max-width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.ecFav img{
  vertical-align: bottom;
  width: 44px;
  height: 44px;
}

.itemPriceBox p, .ecListInner p{
  margin: 4px 0;
  text-align: center;
  word-break : break-all;
}

.ecListInner .price{
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  color: #FF3300;
  text-decoration: none;
  height: 26px;
}

.ecListInner .saletxt{
  display: block;
  font-size: .9rem;
  line-height: 1.1rem;
  text-decoration: none;
  text-align: left;
  height: 100%;
}

.ecListInner .saledate{
  display: block;
  font-size: .65rem;
  height: 14px;
  text-align: left;
  font-weight: bold;
  margin-top: 4px;
  color: #999;
}

.ecListInner .fee{
  display: block;
  font-size: .65rem;
  line-height: 1rem;
  color: #333;
  height: 14px;
}



.ecPriceBoxInner{
  width: 100%;
  padding: 0 10px
}

.ecListSale .ecPriceBoxInner{
  width: 100%;
  padding: 0 6px
}

.itemBox{
  margin: 42px 0;
}

.itemBox.no-ma-top{
  margin-top: 0;
}


.itemBoxInner{
  padding: 16px;
  width: 100%;
  background-color: #FFF;
  box-sizing: border-box;
  border-bottom: 1px solid #F0F0F0
}

.box__title{
  font-size: .85rem;
  margin-bottom: 8px;
  padding: 0 16px;
}

.boxTitle{
  font-size: .85rem;
  margin-bottom: 8px;
  padding: 0 16px;
}

.headTitle{
  font-size: 1rem;
  text-align: center;
  width: 100%;
}

.brandEn{
  font-size: .85rem;
  font-weight: bold;
  display: block;
  padding-bottom: 2px;
}

.brandJa{
  display: block;
  font-size: .65rem;
  color: #999;
}

.iconLink{
  text-decoration: none;
  color: #333;
  text-align: center;
  display: block;
}

.iconLinkIcon{
  border: 1px solid skyblue;
  padding: 8px;
  display: flex;
  font-size: 1.5rem;
  display: block;
  color: skyblue;
  border-radius :50%;
  width: 46px;
  height: 46px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.iconLinkTxt{
  margin-top: 4px;
  font-size: .75rem;
  display: block;
}

.noticeTxt{
  font-size: .7rem;
  color: #999;
}

.hide{
  display: none;
}

.pref__list{
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.pref__list > li{
  background-color: #FFF;
  border-bottom: 1px solid #EEE;
  overflow: hidden;
  position: relative;
}

.pref__list li label{
  display: block;
  padding: 16px 24px;
  border-bottom: 1px solid #EEE;
  cursor: pointer;
}

.pref__list li input{
  display: none;
}

.arrow{
  position: absolute;
  right: 12px;
  top: 20px;
  height: 16px;
  transition: all 0.5s ease;
}

.pref__list--inner{
  transition: all 0.3s ease;
  list-style: none;
  padding-left: 0;
  max-height: 0;
  padding: 0 24px;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.pref__list--inner.static{
  max-height: 100%;
  background-color: #FFF;
}

.pref__list--inner li{
  min-width: 50%;
  max-width: 50%;
}

.pref__list--inner li a{
  text-decoration: none;
  color: #333;
  padding: 12px;
  display: flex;
  font-size: .9rem;
  align-items: center;
  justify-content: space-between;
}

.pref__list--inner li a .name{
  diplay: inline-block;
  margin-right: 16px;
}

.pref__list--inner li a .num{
  diplay: inline-block;
  font-size: .7rem;
  background-color: #1AB3FF;
  border-radius: 16px;
  padding: 3px 8px;
  color: #FFF;
}

.pref__list li input:checked + .arrow{
  transform:rotate(90deg);
}

.pref__list li input:checked ~ ul{
  max-height: 300px;
}

.itemBoxBC{
  list-style: none;
  padding: 0 8px;
  margin: 1px 0 0;
  width: 100%;
  background-color: #FCFCFC;
  border-bottom: 1px solid #EEE;
}

.itemBoxBC li{
  display: inline-block;
}

.itemBoxBC li a{
  display: flex;
  font-size: .8rem;
  padding: 12px 8px;
  positoin: relative;
  word-break: break-all;
  word-wrap: break-word;
}

.itemBoxBC li a:after{
  content: '>';
  padding: 0 0 0 12px;
}

.itemBoxBC li:last-child a:after{
  display: none;
}

.homeBtn{
  position: fixed;
  z-index: 8900;
  font-size: 1.5rem;
  width: 48px;
  height: 48px;
  display:flex;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.5);
  color: #FFF;
  transition: all 0.8s ease;
  bottom: 54px;
  left: -80px;
}

.homeBtn.show{
  left: 12px;
}

.homeBtnInner{
  padding: 6px;
  width: 100%;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
}


.pagerBox{
  transition: all 0.8s ease;
  position: fixed;
  bottom: 40px;
  right: 8px;
  padding: 12px 0;
}

.pagerBox.hidden{
  bottom: -80px;
}

.block{
  display: block;
}

.t-center{
  text-align: center;
}

.t-right{
  text-align: right;
}

.fixInnerTop{
  width: 100%;
  position: fixed;
  top: 46px;
  z-index: 999;
  box-shadow: 0 1px 8px rgba(0,0,0,0.1);
  left: 0;
}

.has__nav__menu{
  padding-top: 38px;
}

.fullLoading{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -80px;
}

.txtMenuBtn{
  padding: 0 12px;
  text-align: right;
  color: #AAA;
  font-size: 1.2rem;
  margin: 8px 0;
}

.textHide{
  height: 0;
  margin: 0;
  overflow: hidden;
}

.flex__center__box{
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.flex__center__box.center{
  justify-content: center;
}


.flex__page{
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 100vh;
  font-size: .8rem;
}

.flex__page--inner{
}

.flex__page h1{
  text-align: center;
  font-size: 1.1rem;
  color: #1ab3ff;
  margin: 24px 0;
}

.flex__page h2{
  text-align: center;
  font-size: .9rem;
  color: 333;
  text-align: left;
  margin-top: 36px;
}

.flex__page--icon{
  width: 100%;
  font-size: 4rem;
  text-align: center;
  line-height: 1rem;
  margin: 0;
  color: #1ab3ff;
}

.flex__page--btn{
  padding: 24px 0;
  text-align: center;
}


.item__detail__btn{
  color: #1ab3ff;
  font-size: .85rem;
  border: 1px solid #CCC;
  padding: 2px;
  border-radius: 50%;
  width: 28px;
  min-width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  opacity: .4;
  transition: all 0.3s ease;
}

.item__detail__btn.active{
  opacity: 1;
}

.item__detail__btn:active{
  animation: bounce 400ms both cubic-bezier(0.165, 0.84, 0.44, 1);
}

.holdList{
  list-style: none;
  padding-left: 0;
  margin: 0;
  border-bottom: 1px solid #F0F0F0;
  padding-bottom: 60px;
}

.holdList > li{
  border-bottom: 1px solid #F0F0F0;
}

.holdList > li:last-child{
  border-bottom: 0;
}

.holdList li a, .holdList li p {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;
  padding: 0 24px;
  position: relative;
  background-color: #FFF;
}

.holdListTxt{
  width: 100%;
  padding: 12px 0;
  display: block;
}

.openArrow{
  transition: all 0.4s ease;
  display: block;
}

.openInpt{
  display: none;
}

.openInpt:checked + p .openArrow{
  transform:rotate(90deg);
}

.childList{
  min-height: 0;
  max-height: 0;
  font-size: .85rem;
  list-style: none;
  overflow: hidden;
  transition: all 0.4s ease;
  padding-left: 24px;
}

.openInpt:checked ~ .childList{
  min-height: 46px;
  max-height: 1000px;
}


.topBannerBox .ecPriceBoxInner{
  padding: 0 4px;
}

#topbnript:checked + .topBanner{
  right: -2px;
}

.topBanner{
  position: fixed;
  bottom: 12px;
  right: -302px;
  width: 300px;
  background-color: #FFF;
  transition: all 0.8s ease;
}

.topBannerBox{
  position: relative;
  padding: 20px 10px 7px;
  border: solid 2px #FFC107;
  border-radius: 3px;
}

.topBannerBox .box-title {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  padding: 1px 9px;
  font-size: .7rem;
  background: #FFC107;
  color: #ffffff;
  font-weight: bold;
}


.brand__link--btn{
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 36px;
  z-index: 1;
  padding: 12px;
  margin: auto;
}

.modal__title{
  font-weight: bold;
  text-align: center;
}

.item__detail--spec{
  border: 1px solid #EEE;
  margin: 8px;
  background-color: #FFF;
  border-radius: 4px;
}

.item__detail--spec--table{
  margin: 0;
  padding: 4px 16px;
  display: flex;
  flex-wrap: wrap;
  font-size: .7rem;
}

.item__detail--spec--table dt{
  display: inline-flex;
  align-items: center;
  width: 50%;
  font-size: .75rem;
  line-height: 1rem;
  padding: 12px 0;
}

.item__detail--spec--table dt .icon{
  padding-right: 16px;
  color: #AAA;
}

.item__detail--spec--table dt .txt{
}

.item__detail--spec--table dd{
  width: 50%;
  margin-left: 0;
  padding: 12px;
  font-size: .8rem;
  line-height: 1rem;
}

.item__detail--spec--table dd a{
  color: #1ab3ff;
}

.item__detail--spec--table--catelist{
  margin: 0;
}

.item__detail--spec--table--catelist li{
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.item__detail--spec--table--catelist li:after{
  content: '>';
  padding-left: 8px;
}

.item__detail--spec--table--catelist li:last-child{
  margin-bottom: 0;
}

.item__detail--spec--table--catelist li:last-child:after{
  content: '';
}

#g_message--check:checked + .g_message{
  bottom: 0;
}

.g_message{
  position: fixed;
  bottom: -500px;
  transition: all 0.5s ease;
}

.g_message--inner{
  background-color: #FFF;
  border: 2px solid #1ab3ff;
  padding: 12px;
  font-size: .8rem;
  width: 100%;
  max-width: 500px;
  position: relative;
}

.g_message--inner h3{
  margin-top: 0;
}

.g_message--inner p{
  font-size: .7rem;
}

.g_message--inner p.last{
  margin-bottom: 0;
}

.g_message--close{
  position: absolute;
  top: 8px;
  right: 8px;
}

@keyframes bounce {
  0% {
    transform:scale(1);
  }
  70% {
    transform:scale(1.3);
  }
  100% {
    transform:scale(1);
  }
}


/* Transitions */

.toboard-leave-to {
  /* animation: leaveFromMain 500ms both cubic-bezier(0.165, 0.84, 0.44, 1); */
  z-index: -1;
}

.toboard-enter-to {
  animation: enterFromMain 300ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 999;
}


@keyframes enterFromMain {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes leaveFromMain {
  from {
    transform: translateX(90%);
    opacity: 0;
  }
  to {
    transform: translateX(100%);
    opacity: 1;
  }
}



.to-right-leave-active {
  /* animation: toRightleave 100ms both cubic-bezier(0.165, 0.84, 0.44, 1); */
  z-index: -1;
  /* display: none; */
  /* will-change: transform; */
}

.to-right-enter {
  opacity: 0;
}

.to-right-enter-to {
  animation: toRightenter 400ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 999;
  /* will-change: transform; */
}

@keyframes toRightenter {
  from {
    transform: translateX(70%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes toRightleave {
  from {
    /* transform: translateX(0); */
    filter: brightness(.6);
  }
  to {
    /* transform: translateX(-10%); */
    filter: brightness(.5);
  }
}

.to-left-leave{
  display: none;
  /* opacity: 0.1; */
}

.to-left-leave-to {
  /* animation: toLeftleave 500ms both cubic-bezier(0.165, 0.84, 0.44, 1); */
  z-index: 0;
  /* will-change: transform; */
}

.to-left-enter-active {
  /* animation: toLeftenter 100ms both cubic-bezier(0.165, 0.84, 0.44, 1); */
  /* z-index: -1; */
  /* will-change: transform; */
}


@keyframes toLeftleave {
  from {
    transform: translateX(90);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes toLeftenter {
  from {
    transform: translateX(-10%);
  }
  to {
    transform: translateX(0);
  }
}

.to-bottom-leave-to {
  animation: toBottomleave 600ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 0;
}

.to-bottom-enter-to {
  animation: toBottomenter 600ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 1;
}

.from-bottom-leave-to {
  animation: fromBottomleave 600ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 0;
}

.from-bottom-enter-to {
  animation: fromBottomenter 600ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}



@keyframes fromBottomleave {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes fromBottomenter {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes toBottomleave {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes toBottomenter {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes leaveToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-25%);
  }
}

@keyframes enterFromLeft {
  from {
    transform: translateX(-25%);
    /* filter: brightness(0.5); */
  }
  to {
    /* transform: translateX(0); */
  }
}

@keyframes leaveToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes enterFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@media (min-width:980px) {
  .container{
    width: 980px;
    margin: auto auto;
  }

  .gheadBox{
    width: 100%;
    margin: auto auto;
  }
  .ghead{
    /* width: 1080px; */
    margin: auto auto;
    box-shadow: none;
  }
}

</style>
