import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueAnalytics from 'vue-analytics'
import VueHead from 'vue-head'
import store from './store';
import './registerServiceWorker';
import Toast from "vue2-toast-plugin";
import VueScrollTo from "vue-scrollto"
import VueLazyload from 'vue-lazyload';
import "vue2-toast-plugin/dist/style.css";
// import Message from 'vue-m-message'
// import firebase from 'firebase'
// import firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/messaging";

import Vue2TouchEvents from 'vue2-touch-events'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueAnalytics, {
  id: 'UA-40140574-2',
  router
})

Vue.use(VueHead)

Vue.use(VueLazyload, {
  preLoad: 1.5,
  error: '//tokyowave.net/img/ldr.gif',
  loading: '//tokyowave.net/img/ldr.gif',
  attempt: 3
})


Vue.use(Vue2TouchEvents, {
  swipeTolerance: 10,
  longTapTimeInterval: 400
})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyD_TTp4Q9eFPOSE6oURZkv_SYZ7Y98zxvg',
    libraries: 'places',
  },
})

// // import VueAwesomeSwiper from 'vue-awesome-swiper'
// // import 'swiper/dist/css/swiper.css'
// Vue.use(VueAwesomeSwiper, /* { default global options } */)
// Vue.use(Message, {name: 'msg'})

Vue.use(Toast, {
    position: 'bottom',
    duration: 3000,
    // wordWrap: true,
    width: '260px',
    throttleWait: 300
  }
);

Vue.use(VueScrollTo, {
     container: ".container",
     duration: 0,
     easing: "ease",
     offset: 0,
     force: true,
     cancelable: true,
     // onStart: false,
     // onDone: false,
     // onCancel: false,
     x: false,
     y: true
 })

// fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faArrowLeft, faArrowRight, faChevronRight, faListUl, faUser, faTh, faPaperclip, faTimesCircle, faStore, faTshirt, faBullhorn, faShoppingBag, faSpinner, faMapMarkedAlt, faMapMarkerAlt, faPhone, faClock, faSubway, faShareAlt, faHome, faHistory, faHeart, faSignOutAlt, faCommentAlt, faStream, faEllipsisV, faCog, faCheck, faTrashAlt, faSadTear, faSlidersH, faArchive, faStar, faPlus, faFolder, faGem, faBarcode, faReceipt, faEyeSlash, faEye, faDirections, faCompressArrowsAlt, faExpandArrowsAlt, faAngleDoubleRight, faBookmark} from '@fortawesome/free-solid-svg-icons'
import { faGoogle, faTwitter, faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faArrowLeft, faArrowRight, faChevronRight, faListUl, faUser, faTh, faPaperclip, faTimesCircle, faStore, faTshirt, faShoppingBag, faBullhorn, faSpinner, faMapMarkedAlt, faMapMarkerAlt, faPhone, faClock, faSubway, faShareAlt, faHome, faHistory, faHeart, faSignOutAlt, faCommentAlt, faStream, faGoogle, faTwitter, faFacebook, faInstagram, faEllipsisV, faCog, faCheck, faTrashAlt, faSadTear, faSlidersH, faArchive, faStar, faPlus, faFolder, faGem, faBarcode, faReceipt, faEyeSlash, faEye, faDirections, faCompressArrowsAlt, faExpandArrowsAlt, faAngleDoubleRight, faBookmark)

// fab.add(faGoogle)

Vue.component('font-awesome-icon', FontAwesomeIcon)
// fontawesome


// firebase
// const firebaseConfig = {
//   apiKey: "AIzaSyALL7yNc9hC9foxzMuT3HSeXLa_9zqWnQ8",
//   authDomain: "tokyowave-auth.firebaseapp.com",
//   databaseURL: "https://tokyowave-auth.firebaseio.com",
//   projectId: "tokyowave-auth",
//   storageBucket: "tokyowave-auth.appspot.com",
//   messagingSenderId: "335850350468",
//   appId: "1:335850350468:web:3a0aa31f43b9cf98"
// };
// firebase.initializeApp(firebaseConfig);
//
// if (firebase.messaging.isSupported()) {
//   const messaging = firebase.messaging();
//   messaging.usePublicVapidKey("BHdMQZMOYKhpkBhzdJCidAYVJU8PyKF5XR9twQvOSkBBZA5oeU5E3qwwC7WHJVte_OIh98_6xlnmc1H23N4YfL0"); // 1で取得した鍵ペア
//
//   // 通知の受信許可
//   messaging.requestPermission().then(() => {
//     // console.log('Notification permission granted.');
//
//     // トークン取得
//     messaging.getToken().then((token) => {
//       // console.log(token)
//     })
//   }).catch((err) => {
//     console.log('Unable to get permission to notify.', err);
//   });
// }
// firebase

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
