<template>
  <ul class="itemList">
    <li v-for="item in lists" :key="item.page">
      <keep-alive>
        <div class="">
          <router-link :to="{ name: 'itemDetail', params: { id: item.id } }" class="itemListBox" :id="'item-' + item.id">
            <itemListBox :item="item"/>
            <div v-if="hideItems.indexOf(parseInt(item.id)) >= 0" class="noview">
              <font-awesome-icon icon="eye-slash" />
            </div>
          </router-link>
          <itemStar :itemId="item.id" :title="item.brand_name_full + ' ' + item.name" />
        </div>
      </keep-alive>
    </li>
  </ul>
</template>

<script>
import itemStar from "@/components/atom/itemStar.vue";
import itemListBox from "@/components/block/itemListBox.vue";
import { mapGetters } from "vuex"

export default {
  name: 'OneItemList',
  props: ['lists'],
  components: {
    itemListBox,
    itemStar
  },
  computed: {
    ...mapGetters([
      'starItems', 'hideItems'
    ])
  },
  created () {
    // console.log('oneitemlist created');
  },
}
</script>

<style scoped>
.itemList{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin: 0 -2px;
  margin: 0;
}

.itemList li{
  min-width: 33.333%;
  max-width: 33.333%;
  padding: 2px;
  box-sizing: border-box;
  position: relative;
}

.itemListBox{
  width: 100%;
  background-color: #FFF;
  display: block;
  text-decoration: none;
  color: #333;
  position: relative;
}

.itemListBox:before{
  content:"";
  display: block;
  padding-top: 100%;
}

.noview{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #AAA;
}

@media (min-width:767px) {
  .itemList li{
    min-width: 20%;
    max-width: 20%;
  }
}


</style>
