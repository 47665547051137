<template>
  <nav>
    <div class="naviContent">
      <input v-for="(page, index) in pageNav.lists" class="hide" type="radio" name="tab" :id="'tab' + index" :checked="page.checked"/>
      <ul id="scroll-tab">
        <li v-for="(page, index) in pageNav.lists">
          <label :for="'tab' + index" :id="'tab-label-' + index" @click="labelLink(page.route)">{{page.label}}</label>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
export default {
  props: ['pageNav'],
  data() {
    return {
    }
  },
  watch: {
  },
  methods: {
    labelLink(route){
      this.$router.push(route)
    },
  }
}
</script>

<style>
#tab0:checked ~ #scroll-tab{
  transform: translateX(0);
}
#tab1:checked ~ #scroll-tab{
  transform: translateX(-50vw);
}
#tab2:checked ~ #scroll-tab{
  transform: translateX(-100vw);
}
#tab3:checked ~ #scroll-tab{
  transform: translateX(-150vw);
}
#tab4:checked ~ #scroll-tab{
  transform: translateX(-200vw);
}

#tab0:checked ~ #scroll-tab #tab-label-0:after,
#tab1:checked ~ #scroll-tab #tab-label-1:after,
#tab2:checked ~ #scroll-tab #tab-label-2:after,
#tab3:checked ~ #scroll-tab #tab-label-3:after,
#tab4:checked ~ #scroll-tab #tab-label-4:after,
#tab5:checked ~ #scroll-tab #tab-label-5:after
{
  opacity: 1;
}

#tab0:checked ~ #scroll-tab #tab-label-0,
#tab1:checked ~ #scroll-tab #tab-label-1,
#tab2:checked ~ #scroll-tab #tab-label-2,
#tab3:checked ~ #scroll-tab #tab-label-3,
#tab4:checked ~ #scroll-tab #tab-label-4,
#tab5:checked ~ #scroll-tab #tab-label-5
{
  color: #FF3300;
}


#scroll-tab{
  transform: translateX(50vw);
  transition: all 0.3s ease;
  padding: 0 25vw;
}

nav {
  background-color: #FFF;
  height: 40px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.naviContent {
  -webkit-user-select: none;
  display: block;
  height: 54px;
  overflow-y: hidden;
  padding: 0;
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.naviContent ul {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0;
}
.naviContent ul li {
  width: calc(100vw /2);
  display: inline-block;
  line-height: 40px;
  height: 40px;
  margin: 0;
  text-align: center;
  font-size: 14px;
  position: relative;
}
.naviContent ul li {
}

.naviContent ul li.current:before{
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #f0980e;
}
.naviContent ul li label {
  font-size: 1em;
  /* padding: 0px 24px; */
  display: block;
  width: 100%;
  color: #888;
  font-size: .8rem;
}

.naviContent ul li label:after{
  content: '';
  height: 2px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-color: #FF3300;
  position: absolute;
  bottom: 3px;
  width: 50%;
  left: 25%;
  opacity: 0;
}
</style>
