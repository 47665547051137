<template functional>
  <div class="loadingBox">
    <span class="loadingIcon" v-bind:class="'size' + props.size"><font-awesome-icon :icon="props.icon" /></span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['size', 'icon']
}
</script>

<style>
.loadingBox{
  text-align: center;
  margin: 16px 0;
}

.loadingIcon{
  display: inline-block;
  animation: loading 1s linear infinite;
}

.loadingIcon.size2{
  font-size: 2rem;
}

.loadingIcon.size3{
  font-size: 3rem;
}

.loadingTxt{
  display: block;
  margin: 4px 0;
  font-size: .7rem;
}

.loadTxt{
  font-size: 12px;
  margin: 0
}

@keyframes loading {
  from {
  }
  to {
    transform: rotate(360deg);
    transform-origin: 50% 50%;
  }
}
</style>
