import Vue from 'vue'
import Router from 'vue-router'

import Home from "./views/Home/Frame.vue";
import ItemDetail from "./views/Items/Detail.vue";
import Search from "./views/Search.vue";
import Offline from "./views/Offline.vue";
import Maintenance from "./views/Maintenance.vue";
import Mypage from "./views/Mypage.vue";
import Board from "./views/Board.vue";

// import firebase from 'firebase/app'

import store from './store';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    // console.log(savedPosition);
    if(to.meta.savePosi && savedPosition){
      return savedPosition
    }
  },
  routes: [
    {
      path: "/index.html",
      name: "index",
      redirect: '/',
      meta: {
        page: 'main',
        home: true
      }
    },
    {
      path: "/",
      component: Home,
      meta: {
        page: 'main',
        home: true,
        savePosi: true,
        search: true,
      },
      children: [
        {
          path: '/',
          name: "home",
          component: Search,
          meta: {
           page: 'main',
           home: true,
           savePosi: true,
           search: true,
          }
        },
        {
          path: '/saved',
          name: "HomeSaved",
          component: () => import( './views/Home/Saved.vue'),
          meta: {
           page: 'main',
           home: true,
           savePosi: true,
          }
        },
        {
          path: '/starred',
          name: "HomeStarred",
          component: () => import( './views/Home/Starred.vue'),
          meta: {
           page: 'main',
           home: true,
           savePosi: true,
          }
        },
        {
          path: '/viewed',
          name: "HomeViewed",
          component: () => import( './views/Home/Viewed.vue'),
          meta: {
           page: 'main',
           home: true,
           savePosi: true,
          }
        },
      ]
    },
    {
      path: "/items/:id",
      name: "itemDetail",
      component: ItemDetail,
      meta: {
        page: 'push',
      }
    },
    {
      path: "/categories/:code",
      component: Search,
      children: [
        {
          name: "CategoryListSearch",
          path: '/',
          component: Search,
          meta: {
            page: 'main',
            savePosi: true,
            home: true,
            search: true,
          }
        },
        {
          name: "CategoryListSearchSub",
          path: ':sub_category_code',
          component: Search,
          meta: {
            page: 'main',
            savePosi: true,
            home: true,
            search: true,
          }
        }
      ]
    },
    {
      path: "/search",
      name: "Search",
      component: Search,
      meta: {
        page: 'main',
        home: true,
        savePosi: true,
        search: true,
      }
    },
    {
      path: '/brand/:code',
      component: () => import( './views/Brands/Frame.vue'),
      meta: {
        page: 'push'
      },
      children: [
        {
          path: '',
          name: "BrandTop",
          component: () => import( './views/Brands/ItemLists.vue'),
          meta: {
           page: 'push'
          }
        },
        {
          path: 'stores',
          component: () => import( './views/Stores/Frame.vue'),
          children: [
            {
              path: '/',
              name: "BrandStoreTop",
              component: () => import( './views/Stores/Top.vue'),
              meta: {
                page: 'push'
              }
            },
            {
              path: ':prefecture_code',
              component: () => import( './views/Stores/Selector.vue'),
              meta: {
                page: 'push'
              },
              children: [
                {
                  path: '/',
                  name: "BrandStorePref",
                  component: () => import( './views/Stores/Counts.vue'),
                  meta: {
                    page: 'push'
                  }
                },
                {
                  path: ':city_code',
                  name: "BrandStoreCity",
                  component: () => import( './views/Stores/Lists.vue'),
                  meta: {
                    page: 'push'
                  }
                },
              ]
            },
          ]
        },
        {
          path: 'categories',
          name: "BrandCategories",
          component: () => import( './views/Brands/Categories.vue'),
          meta: {
           page: 'push',
          }
        },
      ]
    },
    {
      path: '/brand/:code/items/',
      name: "BrandItems",
      component: Search,
      meta: {
        page: 'main',
        savePosi: true,
        home: true,
        search: true,
      },
    },
    {
      path: '/brand/:code/items/:category',
      name: "BrandItemsCategory",
      component: Search,
      meta: {
        page: 'main',
        savePosi: true,
        home: true,
        search: true,
      }
    },
    {
      path: "/store/detail/:id",
      name: "StoreDetail",
      component: () => import( './views/Stores/Detail.vue'),
      meta: {
        page: 'push'
      }
    },
    {
      path: "/mypage",
      name: "mypage",
      component: Mypage,
      meta: {
        page: 'right',
        home: true,
      }
    },
    {
      path: "/board",
      name: "board",
      component: Board,
      meta: {
        page: 'left',
        home: true,
      }
    },
    {
      path: "/login",
      component: () => import( './views/Login/Frame.vue'),
      children: [
        {
          path: '',
          name: "login",
          component: () => import( './views/Login/Top.vue'),
          meta: {
            page: 'bottom'
          }
        },
        {
          path: 'loading',
          component: () => import( './views/Login/Loading.vue'),
          meta: {
            page: 'right'
          }
        },
      ]
    },
    {
      path: '/about',
      component: () => import( './views/Static/About.vue'),
      name: "About"
    },
    {
      path: '/privacy',
      component: () => import( './views/Static/Privacy.vue'),
      name: "Privacy"
    },
    {
      path: '/version',
      component: () => import( './views/Static/Version.vue'),
      name: "Version"
    },
    {
      path: '/notfound',
      component: () => import( './views/NotFound.vue'),
      name: "NotFound"
    },
    {
      path: '/maintenance',
      component: Maintenance,
      name: "Maintenance"
    },
    {
      path: '/offline',
      component: Offline,
      name: "Offline"
    },
    {
      path: '*',
      redirect: '/notfound'
    }
  ]
})

export default router;

router.afterEach((to, from) =>{
  // if(!to.meta.savePosi){
  //   window.scrollTo(0,0)
  // }
  if(store.getters.pageCount < 1){
    store.commit('addPageCount')
  }else{
    store.commit('setHasHistory', true)
  }
})
