<template>
  <p class="moreBtn">
    <span class="moreBtnIcon"><font-awesome-icon icon="check" /></span>
    <span class="moreBtnTxt">全て表示しました</span>
  </p>
</template>

<script>
export default {
  props: ['route', 'label'],
}
</script>

<style scoped>
.moreBtn{
  display: block;
  text-align: center;
  padding: 12px;
  margin: 12px auto;
  color: #1AB3FF;
}

.moreBtnIcon{
  display: block;
  font-size: 2rem;
}

.moreBtnTxt{
  display: block;
  font-size: .75rem;
}
</style>
