<template functional>
  <div class="topBanner">
    <div class="topBannerBox">
      <span class="box-title">セール速報</span>
      <div class="ecListInner">
        <p class="ecFavBox"><span class="ecFav"><img :src="props.data.icon" width="44px" :alt="props.data.ec_store_name + 'のアイコン'"></span></p>
        <div class="ecListInner topbanner">
          <p class="saletxt" v-html="props.data.link"></p>
          <p class="saledate">{{props.data.start_from}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data']
}
</script>
