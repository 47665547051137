<template>
  <div class="gird" id="itemDetail" v-show="display">
    <Head v-bind:data="HeadSetting" />
    <section class="container" v-bind:class="{fixed: Modal}">
      <div class="itemCon">

        <div class="item_box--pc">
          <HeadImgs :lists="itemImgs" />
          <input type="checkbox" class="hide" id="close__btn"/>
          <div class="item_box--pc--inner">
            <div class="item_box--pc--inner--box">
              <label class="item_box--pc--close__btn" for="close__btn"><span class="btnicon"><font-awesome-icon icon="angle-double-right" /></span></label>
              <div class="itemBox no-ma-top" id="item__head">
                <div class="itemBoxInner">
                  <h1 class="item__detail--title">
                    <span class="brand__name">{{itemDetail.brand_name_en}}</span> <span class="item__name">{{itemDetail.name}}</span>
                  </h1>
                  <div class="flex__center__box">
                    <p class="item__detail--price_box">
                      <span v-show="itemDetail.min_price" class="price_now">{{itemDetail.min_price}}</span>
                      <span v-if="isSale" class="price_offrate">{{itemDetail.max_offrate}}%OFF</span>
                    </p>
                    <ItemBtn :item_id="itemId" :title="title" />
                  </div>
                </div>
                <DetailBox :data="itemDetail"/>
              </div>
              <div class="itemBox" id="ecList">
                <h2 class="boxTitle">販売サイト</h2>
                <div class="itemBoxInner">
                  <Eclist :eclist="itemEclist"/>
                </div>
              </div>
              <p class="noticeTxt t-center">※表示価格は全て<b>税込み</b>です</p>
              <div class="itemBox brandDetail no-ma-btm">
                <h2 class="boxTitle">ブランド</h2>
                <div class="flex__center__box">
                  <BrandLink :brand_code="itemDetail.brand_code" :brand_name_en="itemDetail.brand_name_en" :brand_name_ja="itemDetail.brand_name_ja"/>
                  <BrandBtn :brand_id="itemDetail.brand_id" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item_box--pc--aside">
          <div class="itemBox brandDetail">
            <h2 class="boxTitle">{{itemDetail.brand_name_en}}の他の{{itemDetail.category_name}}</h2>
            <Itemlist :lists="CatItem" />
            <moreBtn v-if="CatItemMore" :route="CatItemMore">もっとみる</moreBtn>
          </div>
          <div v-if="RelItem.length > 0" class="itemBox brandDetail">
            <h2 class="boxTitle">こちらの{{itemDetail.category_name}}もオススメ</h2>
            <Itemlist :lists="RelItem" />
          </div>
        </div>
        <div class="item__summary" :class="{active: showSummary}">
          <p class="item__summary--img" v-html="itemImgs.mainImg" v-lazy-container="{ selector: 'img', loading: '/img/ldr.gif', error: '/img/noimage.png' }"></p>
          <p class="item__summary--title">
            <span class="brand">{{itemDetail.brand_name_en}}</span>
            <span class="name">{{itemDetail.name}}</span>
          </p>
          <p class="item__summary--price">{{itemDetail.min_price}} <span v-if="isSale" class="offrate">{{itemDetail.max_offrate}}%OFF</span></p>
          <ItemBtn :item_id="itemId"/>
        </div>
      </div>
    </section>
    <div class="itemImgModal" v-if="Modal" @click="closeModal">
      <p><img v-bind:src="ModalTarget"/></p>
      <p @click="closeModal" class="itemImgModalClose"><font-awesome-icon icon="times-circle" /></p>
    </div>
    <jsonProduct :item="itemDetail" :count="Object.keys(itemEclist).length" />
  </div>
</template>

<script>
import Head from "@/components/block/Head.vue";
import {Mixin} from '@/mixins/common'
import Itemlist from "@/components/block/Itemlist.vue";
import Eclist from "@/components/parts/eclist.vue";
import moreBtn from "@/components/parts/moreBtn.vue";
import HeadImgs from "@/components/parts/items/HeadImgs.vue";
import DetailBox from "@/components/parts/items/DetailBox.vue";
import { mapGetters, mapActions } from "vuex"


import BrandLink from "@/components/parts/BrandLink.vue";
import BrandBtn from "@/components/parts/BrandBtn.vue";
import jsonProduct from "@/components/parts/seo/product.vue";
import cmBtn from "@/components/atom/cmBtn.vue";
import ItemBtn from "@/components/parts/ItemBtn.vue";
import axios from 'axios';

export default {
  name: 'ItemDetail',
  data() {
    return {
      itemId: null,
      HeadSetting: {
        Left: 'backBtn',
        Right: 'Share',
        Center: 'search',
        Opacity: true
      },
      ScrollY: 0,
      Modal: false,
      ModalTarget: '',
      showSummary: false,
      isSale: false,
      itemDetail: {},
      itemImgs: {},
      itemEclist: {},
      CatItem: {},
      CatItemMore: false,
      RelItem: {},
      title: '',
      description: ''
    };
  },
  mixins:[Mixin],
  components: {
    Itemlist,
    Eclist,
    BrandLink,
    Head,
    jsonProduct,
    cmBtn,
    moreBtn,
    HeadImgs,
    DetailBox,
    BrandBtn,
    ItemBtn
  },
  created () {
    this.refresh(this.$route)
    this.pageInit(this.$route)
    this.display = true
    // console.log('Item Detail created');
  },
  mounted () {
    // console.log('Item Detail mounted');
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeRouteUpdate(to, from, next) {
    // console.log('beforeRouteUpdate');
    this.refresh(to)
    this.pageInit(to)
    if(to.name === from.name){
      window.scrollTo(0, 0);
    }
    next()
  },
  computed: {
    getCatItemMore: function() {
      return this.CatItemMore
    },
    ...mapGetters([
      'starItems','hideItems', 'getshareData', 'starBrands', 'hideBrands'
    ])
  },
  beforeDestroy(){
    this.display = false
    // console.log('Detail before Destroy');
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
    meta: function () {
      return [
        { property: 'og:title', content: this.title, id: 'og-title'},
        { property: 'og:description', content: this.description, id: 'og-desc' },
        { name: 'description', content: this.description, id: 'meta-desc'},
      ]
    },
  },
  methods: {
    ...mapActions([
      "storeStars", 'storeHide', 'setshareData', 'addHistory'
    ]),
    addFollow() {
      // console.log('add follow');
    },
    refresh(route) {
      this.itemId = route.params.id
      this.display = false
      this.itemDetail = {}
      this.itemImgs = {}
      this.itemEclist = {}
      this.CatItem = {}
      this.CatItemMore = false
      this.RelItem = {}
      this.isSale = false
      this.title = ''
      this.description = ''
    },
    async pageInit(route) {
      const id = route.params.id
      this.addViewed(id)
      this.display = true
      await this.getData(id)
      this.getImgs(id)
      this.getEclist(id)
      this.getCatItem(id)
      this.getRelItem(id)

    },
    async getData(id){
      const url = this.api + '/items/' + id

      await axios.get(url)
      .then((res) => {
        this.itemDetail = res.data
        if(this.itemDetail.max_offrate != '0')　this.isSale = true;

        // meta
        const fullname = this.itemDetail.brand_name_full + ' ' + this.itemDetail.name + ' [' + this.itemDetail.maker_item_code + ']'
        this.title = fullname
        this.description = fullname + 'の商品詳細ページ。購入できる通販サイトや、似たアイテムを表示しています。'
        this.$emit('updateHead')

        // sharedata
        const shareData = {}
        shareData.title = this.title
        shareData.text = this.title + 'の商品詳細ページ。'
        shareData.url = window.location.href

        this.setshareData(shareData)

        // history
        const data = {}
        data.fullPath = /items/ + id
        data.title = this.title
        data.type = 1

        this.addHistory(data)
      })
      .catch((e) =>{
        // console.log('item detail not found')
      })
    },
    async getImgs(id){
      const url = this.api + '/items/' + id + '/imgs.json'

      await axios.get(url)
      .then((res) => {
        this.itemImgs = res.data
      })
      .catch((e) =>{
        // console.log('item imgs not found')
      })
    },
    async getEclist(id){
      const url = this.api + '/items/' + id + '/eclist/'

      await axios.get(url)
      .then((res) => {
        this.itemEclist = res.data
      })
      .catch((e) =>{
        // console.log('item imgs not found')
      })
    },
    async getCatItem(id){
      const url = this.api + '/items/' + id + '/catitems/'

      await axios.get(url)
      .then((res) => {
        this.CatItem = res.data.list
        if(res.data.item_total > res.data.perpage){
          this.CatItemMore = {}
          this.CatItemMore.name = "BrandItemsCategory"
          this.CatItemMore.params = {}
          this.CatItemMore.params.code = this.itemDetail.brand_code
          this.CatItemMore.params.category = this.itemDetail.category_code
          this.CatItemMore.query = {}
          this.CatItemMore.query.page = 2
        }
      })
      .catch((e) =>{
        // console.log('cat item not found')
      })
    },
    async getRelItem(id){
      const url = this.api + '/items/' + id + '/relitems/'

      await axios.get(url)
      .then((res) => {
        this.RelItem = res.data.list
      })
      .catch((e) =>{
        // console.log('rel item not found')
      })
    },
    showModal(url){
      this.ScrollY = window.scrollY
      this.Modal = true;
      this.ModalTarget = url;
    },
    closeModal(){
      window.scrollTo(0, this.ScrollY);
      this.Modal = false;
      this.ModalTarget = '';
    },
    handleScroll() {
      const target = document.getElementById('item__head')
      const head = document.getElementById('ghead')
      if(target){
        this.scrollY = target.getBoundingClientRect().top;
        if(this.scrollY < -50){
          this.showSummary = true;
          head.classList.remove('opacity');
        }else{
          this.showSummary = false;
          head.classList.add('opacity');
        }
      }
    },
    addViewed(id){
      let viewed =[]
      if (localStorage.getItem('viewed')) {
        viewed = JSON.parse(localStorage.getItem('viewed'))
      }
      if(viewed.indexOf(id) === -1){
        viewed.push(parseInt(id))
        localStorage.setItem('viewed', JSON.stringify(viewed));
      }
    },
  }
}
</script>

<style scoped>
.container{
  padding-top: 0;

}

.item__summary{
  width: 100%;
  position: fixed;
  top: 46px;
  height: 40px;
  font-size: .8rem;
  background-color: #FFF;
  z-index: 9990;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  /* border-bottom: 1px solid #EEE; */
  box-shadow: 0 1px 8px rgba(0,0,0,0.1);
  opacity: 0;
  transition: all .6s ease;
  z-index: -1;
}

.item__summary.active{
  opacity: 1;
  z-index: 1;
}

.item__summary--img{
  height: 36px;
  min-width: 36px;
  max-width: 36px;
  display: flex;
  margin-right: 12px;
  overflow: hidden;
  align-items: center;
}

.item__summary--img > :first-child{
  display: flex;
  align-items: center;
}

.item__summary--title{
  margin: 2px 0;
  font-size: .7rem;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.item__summary--title .brand{
  color: #AAA;
  display: block;
}

.item__summary--title .name{
  text-overflow: ellipsis;
  line-height: 1.4rem;
  /* white-space: nowrap; */
}

.item__summary--price{
  max-width: 120px;
  padding: 2px 8px;
  margin: 0;
  text-align: center;
  line-height: .8rem;
}

.item__summary--price .offrate{
  color: #FF3300;
  font-size: .6rem;
}

.itemCon{
  margin: 0 auto;
  padding: 0 0 16px;
  width: 100%;
  display: block;
}

.imgDetailBtn{
  position: absolute;
  bottom: 8px;
  right: 8px;
  font-size: .7rem;
  text-decoration: none;
  color: #FFF;
  display: block;
  border-radius: 12px;
  background: rgba(0,0,0,.6);
  padding: 4px 8px;
  z-index: 99;
}

.item__detail--title{
  padding-bottom: 4px;
  margin: 0;
  line-height: .8rem;
  display: inline-block;
  width: 100%;
}

.item__detail--title .brand__name{
  line-height: 1rem;
  font-size: .8rem;
  color: #999;
  margin: 0;
  display: block;
}

.item__detail--title .item__name{
  font-size: .9rem;
  margin: 4px 0 12px;
  min-height: 20px;
  font-weight: bold;
}

.item__detail--price_box{
  font-size: .8rem;
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.item__detail--price_box .price_regular{
  color: #999;
  display: block;
}

.item__detail--price_box .price_now{
  color: #FF3300;
  font-size: 1.1rem;
  display: inline-block;
  font-weight: bold;
  margin-right: 6px;
}

.item__detail--price_box .price_offrate{
  color: #FFF;
  font-size: .6rem;
  background-color: #FF3300;
  padding: 2px 4px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 6px;
}

.item__detail--btn__box{
  width: 140px;
  text-align: right;
}

/* item__detail--specはApp.vueに移動 */

.imgDetailBtn .icon{
  padding-right: 6px;
}


.brandTxt{
  font-size: .75rem;
  text-align: left;
  margin-bottom: 0;
}


.itemImgModal{
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,.95);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
}

.itemImgModal p img{
  width: 100%;
}

.itemImgModalClose{
  position: absolute;
  top: 4px;
  right: 4px;
  color: #FFF;
  font-size: 2rem;
  cursor: pointer;
}

.item_box--pc--close__btn{
  position: absolute;
  right: 1px;
  width: 36px;
  height: 36px;
  padding: 4px;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  display: none;
}


@media (min-width:980px) {
  .container{
    width: 100%;
    margin: auto auto;
  }

  .item_box--pc{
    position: relative;
    width: 100%;
  }

  .item_box--pc--inner{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1px;
    width: 380px;
    display: flex;
    align-items: center;
    transition: all .6s ease;
  }

  .item_box--pc--inner--box{
    background-color: #FFF;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 1px 6px rgba(0,0,0,0.1);
  }

  .item_box--pc--aside{
    width: 980px;
    margin: 0 auto;
  }

  .item_box--pc--inner .itemBox{
    margin: 16px 0;
  }

  .item_box--pc--inner .no-ma-btm{
    margin-bottom: 0;
  }

  .item_box--pc--inner .no-ma-top{
    margin-top: 0;
  }

  .item_box--pc--close__btn{
    display: flex;
  }

  .item_box--pc--close__btn .btnicon{
    transition: all .3s ease;
  }


  #close__btn:checked ~ .item_box--pc--inner{
    right: -381px;
  }

  #close__btn:checked ~ .item_box--pc--inner .item_box--pc--close__btn{
    right: auto;
    left: -36px;
  }

  #close__btn:checked ~ .item_box--pc--inner .item_box--pc--close__btn .btnicon{
    transform: rotate(180deg)
  }
}

</style>
