<template functional>
  <span class="cm__button" :class="props.position" @click="props.func(props.funcparams)">
    <span class="cm__button--inner" :class="{active: props.valid}">
      <span class="cm__button__icon" v-show="props.valid"><font-awesome-icon icon="plus" /></span>
      <span class="cm__button__txt">{{props.label}}</span>
    </span>
  </span>
</template>

<script>
export default {
  props: ['label', 'position', 'func', 'valid', 'funcparams'],
  data() {
    return {
      // valid: false,
    }
  },
}
</script>

<style>
.cm__button{
  align-items: center;
  display: flex;
  position: relative;
  margin: 0 16px;
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
}

.cm__button--inner{
  color: #1ab3ff;
  font-size: .75rem;
  display: inline-flex;
  align-items: center;
  border: 1px solid #1ab3ff;
  border-radius: 8px;
  padding: 4px 6px;
  position: relative;
  white-space: nowrap;
  z-index: 2;
  background-color: #FFF;
  transition: all 0.4s ease;
}


.cm__button--inner:active{
  background-color: #1ab3ff;
  color: #FFF;
}

.cm__button--inner.active{
  background-color: #1ab3ff;
  color: #FFF;
}


.cm__button.right{
  right: 24px;
}

.cm__button.left{
  left: 24px;
}


.cm__button__icon{
  padding-right: 4px;
}

.cm__button__txt{
  display: inline-block;
}
</style>
