<template>
  <div class="pageBox" :class="{has__nav__menu: isHome}">
    <div id="itemList">
      <div v-show="isLoading" class="fullLoading">
        <loading :size="2" :icon="'spinner'" />
      </div>
      <div v-show="!isLoading">
        <div v-if="getPageTotal" v-for="(data, index) in getList" :key="data.page" class="onelist" :id="'onepage-' + data.page">
          <h2 class="hide">{{data.page}}ページ目</h2>
          <Itemlist v-bind:lists="data.items" />
          <loading v-if="data.loading" :size="2" :icon="'spinner'">
            <p class="loadTxt" v-if="'parseInt(data.page) + 1 < pageTotal'" :id="'page' + Number(parseInt(data.page) + 1)">{{data.next}}ページ目をロードしています</p>
          </loading>
          <Recobox v-if="brandReco && index == 1" :lists="brandReco" />
        </div>
        <p v-else>
          <SadTear>
            <p class="t-center">アイテムが見つかりませんでした</p>
          </SadTear>
        </p>
      </div>
    </div>
    <div class="pagerBox" :class="{hidden: isLoading}">
      <Pager :bathPath="basePath" :page="getPage" :pageTotal="pageTotal" :pageMax="5" />
    </div>
    <p v-show="isSaved" class="saveBtn" :class="{active: saveBtn}" @click="toggleModal"><span class="saveBtnIcon"><font-awesome-icon icon="archive" /></span><span class="saveBtnTxt">検索条件を保存</span></p>
    <bottomLogo v-show="loadEnd" />
    <modalAlert v-if="modalOn">
      <div class="modal-box__block__inner">
        <p class="modal__title">検索条件を保存しますか？</p>
        <p class="flex__center__box center">
          <label for="viewtop">HOMEメニューに表示する</label>
          <input type="checkbox" name="viewtop" v-model="saveAsTopView" id="viewtop" />
        </p>
      </div>
      <div class="modal-box__block__buttons">
        <div class="modal-box__block__buttons__btn cancel" @click="toggleModal(false)">
          キャンセル
        </div>
        <div class="modal-box__block__buttons__btn" @click="storeCondition">
          OK
        </div>
      </div>
    </modalAlert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Itemlist from "@/components/block/Itemlist.vue";
import loading from "@/components/atom/loading.vue";
import SadTear from "@/components/atom/sad-tear.vue";
import Pager from "@/components/block/Pager.vue";
import Recobox from "@/components/parts/recobox.vue";
import axios from 'axios';
import bottomLogo from "@/components/atom/logo.vue";
import {Mixin} from '@/mixins/common'
import modalAlert from "@/components/parts/modalAlert.vue";

export default {
  name: 'SearchListBox',
  props: ['query', 'basePath', 'brandCode'],
  data() {
    return {
      display: false,
      dataList: [],
      pageTotal: false,
      perpage: 30,
      pageLoaded: [],
      pageNumber: 1,
      loadable: true,
      isLoading: true,
      listLoading: false,
      loadEnd: false,
      brandReco: false,
      title: '',
      description: '',
      saveBtn: false,
      start_pos: 0,
      modalOn: false,
      notSaved: true,
      saveAsTopView: false,
    };
  },
  mixins:[Mixin],
  components: {
    Itemlist,
    loading,
    Pager,
    SadTear,
    bottomLogo,
    Recobox,
    modalAlert,
  },
  created () {
    // console.log('SearchListBox created');
    window.scrollTo(0,0)
    this.setupPage(this.$route)
  },
  mounted () {
    // console.log('SearchListBox mounted');
  },
  beforeMount () {
    window.addEventListener('scroll', this.scrollHandler);
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  activated(){
    // console.log('search activated');
    this.display = true;
    window.addEventListener('scroll', this.scrollHandler);
    this.$emit('updateHead')
  },
  deactivated(){
    // console.log('search deactivated');
    this.display = false;
    window.removeEventListener('scroll', this.scrollHandler);
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  watch: {
    '$route' (to, from) {
      this.clearHeadLink()

      if(to.meta.search){
        // window.addEventListener('scroll', this.scrollHandler);
      }else{
        window.removeEventListener('scroll', this.scrollHandler);
      }
      // console.log('SearchListBox route change watch');
      // this.setupPage(to)
    }
  },
  computed: {
    isHome: function() {
      // console.log(this.$route.name );
      return (this.$route.name === 'home')? true:false;
    },
    getPage: function() {
      return this.pageNumber
    },
    getPageTotal: function() {
      return this.pageTotal
    },
    getQuery: function() {
      return this.query
    },
    getList: function() {
      // console.log('retrun datalist');
      return this.dataList;
    },
    getRel: function() {
      let rel = []

      if(this.getPage != 1){
        const prev = { rel: 'prev', id: 'rel-prev', undo: false}
        prev.href = this.$route.path + '?page=' + (parseInt(this.getPage) - 1)
        rel.push(prev)
      }else{
        const prevdom = document.getElementById("rel-prev")
        if(prevdom !== null){
          prevdom.parentNode.removeChild(prevdom);
        }
      }

      if(parseInt(this.getPage) < parseInt(this.pageTotal)){
        const next = { rel: 'next', id: 'rel-next', undo: false}
        next.href = this.$route.path + '?page=' + (parseInt(this.getPage) + 1)
        rel.push(next)
      }else{
        const nextdom = document.getElementById("rel-next")
        if(nextdom !== null){
          nextdom.parentNode.removeChild(nextdom);
        }
      }

      //canonical
      const canonical = { rel: 'canonical', href: this.basePath.replace('?', ''), id: 'canonical', undo: false }
      rel.push(canonical)

      return rel
    },
    ...mapGetters([
      'isLoadable', 'searchWord', 'searchWordHistory'
    ]),
    isSaved: function(){
      if(this.$route.name === 'home'){
        this.notSaved = false;
      }else{
        for(let i in this.searchWordHistory){
          if(this.$route.fullPath === this.searchWordHistory[i].fullPath && this.searchWordHistory[i].saved) {
            this.notSaved = false;
          }
        }
      }
      return this.notSaved;
    }
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
    meta: function () {
      return [
        { property: 'og:title', content: this.title + ' | TOKYOWAVE', id: 'og-title'},
        { property: 'og:description', content: this.description, id: 'og-desc' },
        { name: 'description', content: this.description, id: 'meta-desc'},
      ]
    },
    link: function () {
      return this.getRel
    }
  },
  methods: {
    ...mapActions([
      "flagSearchWordHistory", 'setSearchWordTopMenu'
    ]),
    initialize(){
      this.dataList = []
      this.pageNumber = null
      this.isLoading = true
      this.listLoading = false
      this.brandReco = false
      this.title = ''
      this.description = ''
      // console.log('date initialized');
    },
    async setupPage(route){
      this.initialize()

      if(route.query.page){
        this.pageNumber = parseInt(route.query.page)
      }else{
        this.pageNumber = 1
      }
      // console.log('setupPage ' + this.getPage) ;
      await this.getData(this.pageNumber);

      // ブランドおすすめの制御
      if(this.brandCode !== null && this.brandCode !== false && this.brandCode !== undefined){
        this.brandReco = true
        this.getRelBrands()
      }
    },
    async getData(page){
      this.listLoading = true
      this.loadable = false;

      let url = this.api + this.query + '&page=' + page

      // console.log(url);
      await axios.get(url)
      .then((res) => {
        // console.log('apiからデータをget');

        // データ追加
        this.pageTotal = res.data.page_total
        const pageData = {page: page, next: parseInt(page) + 1, items: res.data.list, loading: true}

        // ページング
        if(page == this.pageTotal){
          pageData.loading = false
        }

        let mode = ''
        // console.log(page);
        if(this.pageNumber <= page){
          this.dataList.push(pageData)
          mode = 'next'
        }
        if(this.pageNumber > page){
          this.dataList.unshift(pageData)
          if(page > parseInt(1)) mode = 'prev'
        }

        //前のぺーじブロックにあるローディングをfalseにする
        this.dataList.find((onepage) => {
          if(mode === 'next'){
            // console.log((parseInt(page) - 1) + 'についているのloadingを消します');
            if(parseInt(onepage.page) === (parseInt(page) - 1)){
              onepage.loading = false;
            }
          }
          if(mode === 'prev'){
            if(parseInt(onepage.page) === (parseInt(page))){
              // console.log(page + 'のloadingを消します');
              onepage.loading = false;
            }
          }
        });

        // 処理済みページに追加
        this.pageLoaded.push(parseInt(page))
        if(parseInt(page) === this.pageTotal){
          this.loadEnd = true
        }

        this.isLoading = false

        // meta
        if(this.$route.name !== 'home'){
          this.title = res.data.title
        }else{
          this.title = 'HOME'
        }
        this.description = this.title + 'ページ。お気に入りのアイテムを様々な条件から探すことができます。'
        this.$emit('updateHead')

      })
      .catch((e) =>{
        // console.log(e);
        // console.log('item list not found')
       // this.$router.push({ name: "404"})
      })
      this.listLoading = false
      this.loadable = true
    },
    getViewPage(){
      let pages = document.getElementsByClassName('onelist');
      for (var i=0; i < pages.length; i += 1) {
        const pageId = pages[i].id.replace('onepage-', '');
        const pagePosition = pages[i].getBoundingClientRect().top
        // console.log('ページ' + pageId + 'は' + pagePosition);

        if(pagePosition < 90 && pagePosition > -3500){
          let url = this.basePath
          // console.log(this.getPage);
          // console.log(pageId);
          // console.log(this.pageNumber != pageId);
          if(parseInt(this.getPage) != pageId){
            if(url.match(/&/g)){
              url += '&page=' + pageId
            }else{
              url += 'page=' + pageId
            }
            // history.pushState('', '', url);
            this.pageNumber = pageId

            this.$emit('updateHead')
          }
        }
      }
    },
    async getRelBrands(){
      let url = this.api + '/brands/' + this.brandCode + '/relbrands'

      // console.log(url);
      await axios.get(url)
      .then((res) => {
        this.brandReco = res.data
      })
      .catch((e) =>{
      })
    },
    clearHeadLink(){
      const metaList = ['canonical', 'rel-next', 'rel-prev']
      for(let i in metaList){
        const dom = document.getElementById(metaList[i])
        if(dom !== null && dom !== undefined){
          dom.parentNode.removeChild(dom);
        }
      }
    },
    async scrollHandler(router){
      //表示されているのは何ページ目か
      this.getViewPage();

      // console.log(this.isLoadable);
      if(this.loadable === true && this.listLoading === false){
        const target = document.getElementById('itemList')
        const windowHeight = window.innerHeight
        const scrollY = target.getBoundingClientRect().bottom
        const boxHeight = target.scrollHeight
        // console.log(boxHeight);
        // console.log(scrollY);

        // スクロール判定 && 保存ボタン
        if(this.start_pos === 0) this.saveBtn = scrollY

        const scrollHandle = (scrollY < this.start_pos)? false: true;

        this.saveBtn = scrollHandle
        this.start_pos = scrollY


        if(target){
          // 前後を判定
          let loadPage = false;
          if(Number(scrollY - windowHeight) < 160 && scrollHandle === false){
            // console.log('次を読む');
            loadPage = parseInt(this.getPage) + 1
          }
          if(Number(boxHeight - scrollY) < 10 && scrollHandle === true){
            // console.log('前を読む');
            loadPage = parseInt(this.getPage) - 1
          }

          // // ページが長いと重くなるから、途中で上のデータを削除する
          if(this.pageLoaded.length > 6){
            // console.log('リストデータをリフレッシュします');
            this.dataList = []
            this.pageLoaded = []
            this.isLoading = true
            window.scrollTo(0,0)

            await this.getData(this.getPage)
          }

          // データを追加
          if(loadPage !== false && loadPage > 0 && this.loadable === true){
            if((loadPage - this.pageNumber) === 1　|| (this.pageNumber - loadPage) === 1){
              if(this.getPage < this.pageTotal){
                if(this.pageLoaded.indexOf(loadPage) === -1 && loadPage !== false){
                  await this.getData(loadPage)
                }
              }
            }
          }
        }
      }
    },
    toggleModal(valid){
      if(valid){
        this.modalOn = valid
      }else{
        this.modalOn = (this.modalOn)? false: true;
      }
    },
    storeCondition(){
      let mes = ''
      this.notSaved = false;

      this.flagSearchWordHistory(this.$route.fullPath)

      if(this.saveAsTopView === true){
        const val = {}
        val.fullPath = this.$route.fullPath
        val.apiQuery = this.query
        val.basePath = this.basePath

        this.setSearchWordTopMenu(val)

        mes = '検索条件を保存しトップに追加しました。'
      }else{
        mes = '検索条件を保存しました。'
      }

      this.modalOn = false
      this.$toast(mes, {
                position: 'top',
                duration: 2000
            });

    }
  }
}
</script>

<style scoped>


.nextPage{
  font-size: .8rem;
  font-weight: normal;
}

.saveBtn{
  position: fixed;
  bottom: -50px;
  width: 100%;
  margin: 0;
  background-color: #FF9900;
  color: #FFF;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content:center;
  transition: all 0.4s ease;
}

.saveBtn.active{
  bottom: 0;
}

.saveBtnIcon{
  display: inline-block;
  padding-right: 12px;
  font-size: 1.3rem;
}

.saveBtnTxt{
  font-size: .9rem;
  line-height: .9rem;
}

</style>
