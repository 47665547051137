<template>
  <ul class="holdList">
    <li v-for="one in data" :key="one.label">
      <router-link v-if="one.type === 'router-link'" :to="one.params">
        <span class="holdListTxt" @click="searchOff">{{one.label}}</span>
        <font-awesome-icon icon="chevron-right" />
      </router-link>
      <input v-if="one.type === 'open'" class="openInpt" type="checkbox" v-bind:id="'list-' + one.key">
      <p v-if="one.type === 'open'">
        <label v-bind:for="'list-' + one.key" class="holdListTxt">{{one.label}}</label>
        <span class="openArrow"><font-awesome-icon icon="chevron-right" /></span>
      </p>
      <ul class="childList" v-if="one.children">
        <li v-for="child in one.children" :key="child.label">
          <router-link v-if="child.type === 'router-link'" :to="child.params">
            <span class="holdListTxt"  @click="searchOff">{{child.label}}</span>
            <font-awesome-icon icon="chevron-right" />
          </router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>
<script>
import {Mixin} from '@/mixins/common'

export default {
  props: ['data'],
  components: {
  },
  mixins:[Mixin],
  methods: {
  },
}
</script>

<style scoped>
</style>
