import Vue from 'vue'
import Vuex from 'vuex'
import dayjs from 'dayjs'

Vue.use(Vuex)
import {Mixin} from '@/mixins/common'

export const store = new Vuex.Store({
  state: {
    isSA: false,
    searchOpen: false,
    searchWord: '',
    searchWordHistory: [],
    hasHistory: false,
    pageCount: 0,
    pageBack: false,
    searchTabs: '1',
    pwaInstalled: false,
    Installable: false,
    isLogin: false,
    LoginLoading: false,
    userData: null,
    isLoadable: true,
    starItems: [],
    hideItems: [],
    starBrands: [],
    hideBrands: [],
    shareData: {},
    viewedSale: [],
    userHistories: [],
    gMesFlag: []
  },
  mixins:[Mixin],
  mutations: {
    setStandalone(state, valid){
      state.isSA = valid
    },
    setHasHistory(state, valid){
      state.hasHistory = valid
    },
    addPageCount(state){
      state.pageCount += 1
    },
    setPageBack(state, valid){
      state.pageBack = valid
    },
    setSearchOpen(state, valid){
      state.searchOpen = valid
    },
    setSearchTabs(state, value){
      state.searchTabs = value
    },
    setInstallable(state, value){
      state.Installable = value
    },
    setLogin(state, value){
      state.isLogin = value
    },
    setUserData(state, value){
      state.userData = value
    },
    setLoginLoading(state, value){
      state.LoginLoading = value
    },
    setLoadable(state, value){
      state.isLoadable = value
    },
    setSearchWord(state, value){
      state.searchWord = value
    },
    setshareData(state, value){
      state.shareData = value
    },
    setgMesFlag(state, value){
      if(!state.gMesFlag.includes(value)){
        state.gMesFlag.push(value)
      }
      localStorage.setItem('gMesFlag', JSON.stringify(state.gMesFlag));
    },
    removeSearchWordHistory(state, value){
      state.searchWordHistory = state.searchWordHistory.filter(function(item) {
        if(item.fullPath !== value) return item
      });
      localStorage.setItem('searchWordHistory', JSON.stringify(state.searchWordHistory));
    },
    addSearchWordHistory(state, value){
      let add = true
      // console.log(value);

      state.searchWordHistory = state.searchWordHistory.filter(function(item) {
        if(item.fullPath === value.fullPath){
          add = false
        }
        return item
      });
      if(add === true) state.searchWordHistory.unshift(value)
      localStorage.setItem('searchWordHistory', JSON.stringify(state.searchWordHistory));
    },
    flagSearchWordHistory(state, fullPath){
      state.searchWordHistory = state.searchWordHistory.filter(function(item) {
        if(item.fullPath === fullPath){
          item.saved = true
        }
        return item
      });
      localStorage.setItem('searchWordHistory', JSON.stringify(state.searchWordHistory));
    },
    setSearchWordTopMenu(state, val){
      state.searchWordHistory = state.searchWordHistory.filter(function(item) {
        if(item.fullPath === val.fullPath){
          if(item.topview === undefined || item.topview === false){
            item.topview = true
            if(!item.apiQuery) item.apiQuery = val.apiQuery
            if(!item.basePath) item.basePath = val.basePath
          }else{
            item.topview = false
          }
        }
        return item
      });
      localStorage.setItem('searchWordHistory', JSON.stringify(state.searchWordHistory));
    },
    addViewedSale(state, id){
      if(state.viewedSale.indexOf(id)){
        state.viewedSale.unshift(id)
      }
    },

    clearStar(state,list){
      for(let i in list){
        const listName = 'star' + list[i]
        console.log(listName);

        state[listName] = []
        if (localStorage.getItem(listName)) {
          localStorage.setItem(listName, JSON.stringify(state[listName]));
        }
      }
    },
    restoreStar(state,list){
      for(let i in list){
        const listName = 'star' + list[i]
        if (localStorage.getItem(listName)) {
          state[listName] = JSON.parse(localStorage.getItem(listName))
        }
      }
    },
    restoreHide(state,list){
      for(let i in list){
        const listName = 'hide' + list[i]
        if (localStorage.getItem(listName)) {
          state[listName] = JSON.parse(localStorage.getItem(listName))
        }
      }
    },
    addStar(state, value){
      const listName = 'star' + value.list

      const targetId = parseInt(value.target)

      // データ追加
      if(state[listName].indexOf(targetId) === -1){
        state[listName].unshift(targetId)
      }else{
        state[listName] = state[listName].filter(n => n !== targetId);
      }

      // localStorageも更新
      localStorage.setItem(listName, JSON.stringify(state[listName]));
    },
    addHide(state, value){
      const listName = 'hide' + value.list

      const targetId = parseInt(value.target)

      // データ追加
      if(state[listName].indexOf(targetId) === -1){
        state[listName].unshift(targetId)
      }else{
        state[listName] = state[listName].filter(n => n !== targetId);
      }

      // localStorageも更新
      localStorage.setItem(listName, JSON.stringify(state[listName]));
    },
    addHistory(state, value){
      // type = 1=>view, 2=starred, 3=>unstarred
      const now = dayjs().unix()
      if(value.title !== ''){
        const data = {}
        data.time = now
        data.fullPath = value.fullPath
        data.title = value.title
        data.type = value.type

        // console.log(data);

        // state.userHistories = []

        // 同じパスの履歴を削除

        let change = false
        if(state.userHistories.length > 0){
          // state.userHistories = state.userHistories.filter(n =>
          //    n.fullPath !== data.fullPath
          // );
          state.userHistories = state.userHistories.filter(function(item) {
            if(item.fullPath === data.fullPath && item.type === data.type){
              item.time = now
              change = true
            }
            return item
          });
        }
        if(change === false) state.userHistories.unshift(data)

        // sort
        state.userHistories.sort(function(a,b){
          if(a.time > b.time) return -1;
          if(a.time < b.time) return 1;
          return 0;
        });

        localStorage.setItem('userHistories', JSON.stringify(state.userHistories));
      }
    },
    restoreHistory(state){
      if (localStorage.getItem('userHistories')) {
        state.userHistories = JSON.parse(localStorage.getItem('userHistories'))
      }
    },
    restoreSearchWordHistory(state){
      if (localStorage.getItem('searchWordHistory')) {
        state.searchWordHistory = JSON.parse(localStorage.getItem('searchWordHistory'))
      }
    },
    restoreState(state, stateList){
      for(let i in stateList){
        if (localStorage.getItem(stateList[i])) {
          state[stateList[i]] = JSON.parse(localStorage.getItem(stateList[i]))
        }
      }
    }
  },
  actions: {
    storeStars({ commit }, value) {
      commit('addStar', value)
    },
    restoreStar({ commit }, list) {
      commit('restoreStar', list)
    },
    restoreHistory({ commit }, list) {
      commit('restoreHistory', list)
    },
    clearStar({ commit }, list) {
      commit('clearStar', list)
    },
    storeHide({ commit }, value) {
      commit('addHide', value)
    },
    restoreHide({ commit }, list) {
      commit('restoreHide', list)
    },
    setshareData({ commit }, data) {
      commit('setshareData', data)
    },
    addViewedSale({ commit }, id) {
      commit('addViewedSale', id)
    },
    addHistory({ commit }, value) {
      commit('addHistory', value)
    },
    setSearchWord({ commit }, value) {
      commit('setSearchWord', value)
    },
    flagSearchWordHistory({ commit }, value) {
      commit('flagSearchWordHistory', value)
    },
    setSearchWordTopMenu({ commit }, value) {
      commit('setSearchWordTopMenu', value)
    },
    restoreSearchWordHistory({ commit }, value) {
      commit('restoreSearchWordHistory', value)
    },
    removeSearchWordHistory({ commit }, value) {
      commit('removeSearchWordHistory', value)
    },
    setgMesFlag({ commit }, value) {
      commit('setgMesFlag', value)
    },
    restoreState({ commit }, stateList) {
      commit('restoreState', stateList)
    },    
  },
  getters: {
    isSA(state) {
      return state.isSA;
    },
    hasHistory(state) {
      return state.hasHistory;
    },
    pageCount(state) {
      return state.pageCount;
    },
    getPageBack(state) {
      return state.pageBack;
    },
    searchOpen(state) {
      return state.searchOpen;
    },
    searchTabs(state) {
      return state.searchTabs;
    },
    Installable(state) {
      return state.Installable;
    },
    isLogin(state) {
      return state.isLogin;
    },
    userData(state) {
      return state.userData;
    },
    LoginLoading(state) {
      return state.LoginLoading;
    },
    isLoadable(state) {
      return state.isLoadable;
    },
    searchWord(state){
      return state.searchWord;
    },
    searchWordHistory(state){
      return state.searchWordHistory;
    },
    starItems(state){
      return state.starItems;
    },
    hideItems(state){
      return state.hideItems;
    },
    starBrands(state){
      return state.starBrands;
    },
    hideBrands(state){
      return state.hideBrands;
    },
    getshareData(state){
      return state.shareData;
    },
    viewedSale(state){
      return state.viewedSale;
    },
    userHistories(state){
      return state.userHistories;
    },
    pwaInstalled(state){
      return state.pwaInstalled;
    },
    gMesFlag(state){
      return state.gMesFlag;
    },
  }
})
export default store;
