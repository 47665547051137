<template>
  <div class="serachBox">
    <ul class="searchTabs">
      <li>
        <p class="searchTab" v-on:click="change('1')" v-bind:class="{'active': searchTabs === '1'}">
          <span class="pageMenusTxt">キーワード</span>
        </p>
      </li>
      <li>
        <p class="searchTab" v-on:click="change('2')" v-bind:class="{'active': searchTabs === '2'}">
          <span class="pageMenusTxt">カテゴリ</span>
        </p>
      </li>
      <li>
        <p class="searchTab" v-on:click="change('3');brandOpen()" v-bind:class="{'active': searchTabs === '3'}">
          <span class="pageMenusTxt">ブランド</span>
        </p>
      </li>
    </ul>
    <div class="serachBoxInner">
      <div class="serachBlock" v-show="searchTabs === '1'">
        <h2 class="boxTitle">保存済み</h2>
        <ul class="kHistory">
          <li v-for="(keyword, index) in history.saved" :key="index">
            <router-link @click.native="searchOff()" :to="keyword.fullPath">
              <span class="kHistory-icon"><font-awesome-icon icon="bookmark" /></span>
              {{keyword.keyword}}
            </router-link>
            <cmBtn :label="'削除'" :valid="false" :position="'right'" :func="deleteSavedCondition" :funcparams="keyword.fullPath"/>
          </li>
        </ul>
        <h2 class="boxTitle">検索履歴</h2>
        <ul class="kHistory">
          <li v-for="(keyword, index) in history.viewed" :key="index">
            <router-link @click.native="searchOff()" :to="keyword.fullPath">
              <span class="kHistory-icon light"><font-awesome-icon icon="history" /></span>
              {{keyword.keyword}}
            </router-link>
            <cmBtn :label="'保存'" :position="'right'" :valid="true"  :func="addSavedCondition" :funcparams="keyword.fullPath"/>
          </li>
        </ul>
      </div>
      <div class="serachBlock" v-show="searchTabs === '2'">
        <HoldList v-bind:data="categoriyMenus" />
      </div>
      <div class="serachBlock" v-show="searchTabs === '3'">
        <keep-alive>
          <HeadBrands ref="HeadBrands" />
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import HoldList from "@/components/parts/HoldList.vue";
import HeadBrands from "@/components/parts/Brands.vue";
import cmBtn from "@/components/atom/cmBtn.vue";
import {Mixin} from '@/mixins/common'
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      brandOpened: false,
    };
  },
  computed: {
    ...mapGetters([
      'searchTabs'
    ]),
    history: {
      get () {
        const list = []
        list.saved = this.$store.state.searchWordHistory.filter(function(item) {
          if(item.saved) return item
        });
        list.viewed = this.$store.state.searchWordHistory.filter(function(item) {
          if(item.saved === undefined) return item
        });
        // console.log(list);
        return list
      }
    },
  },
  mixins:[Mixin],
  components: {
    HoldList,
    HeadBrands,
    cmBtn
  },
  created(){
    // console.log(this.categories);
    // this.convertBrands()
  },
  methods: {
    ...mapActions([
      "removeSearchWordHistory", 'flagSearchWordHistory'
    ]),
    change: function(num){
      this.$store.commit('setSearchTabs', num)
    },
    brandOpen(){
      this.$refs.HeadBrands.getData()
    },
    deleteSavedCondition(value){
      // console.log('delete ');
      this.removeSearchWordHistory(value)
    },
    addSavedCondition(fullPath){
      // console.log('add ');
      this.flagSearchWordHistory(fullPath)
    },
  }
}
</script>

<style scoped>
.serachBoxInner{
  background-color: #FFF;
}

.searchTabs{
  width: 100%;
  display: flex;
  list-style: none;
  padding-left: 0;
  flex-wrap: wrap;
  padding: 0 8px;
  margin: 4px 0;
  justify-content: center;
}

.searchTabs li{
  max-width: 33.3333%;
  min-width: 33.3333%;
  text-align: center;
}

.searchTab{
  margin: 0;
  text-align:center;
  display: inline-block;
  padding: 8px 12px;
  position: relative;
  font-size: .8rem;
}

.searchTab.active:after{
    content: '';
    height: 3px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    background-color: #FF3300;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
}

.serachBlock{
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.kHistory{
  margin: 0;
  list-style: none;
  padding-left: 0;
}

.kHistory li{
  position: relative;
}

.kHistory a{
  display: block;
  padding: 12px 24px;
  border-bottom: 1px solid #EEE;
  font-size: .9rem;
}

.kHistory-icon{
  display: inline-block;
  padding-right: 16px;
  color: #1ab3ff;
}

.kHistory-icon.light{
  color: #999;
}
</style>
