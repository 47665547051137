<template>
  <div class="gird">
    <Head v-bind:data="HeadSetting" />
    <section class="container">
      <!-- <p class="txtMenuBtn"><font-awesome-icon icon="cog" /></p> -->
      <ul>
        <li v-for="(list, date) in getTimeline" :key="date">
          <p class="stream__date">{{date}}</p>
          <ul class="stream__list">
            <li class="stream__line" v-for="(data, index) in list" :key="index" :class="{ light: data.type === 1}">
              <p class="stream__line--icon">
                <span class="stream__line--icon--img" v-if="data.type === 2"><font-awesome-icon icon="star" /></span>
                <span class="stream__line--icon--img" v-if="data.type === 1"><font-awesome-icon icon="eye" /></span>
              </p>
              <div class="stream__line--txt">
                <p class="stream__line--txt__inner">
                  <router-link :to="data.fullPath">{{data.title}}</router-link>
                  <span v-if="data.type === 1">をチェックしました。</span>
                  <span v-if="data.type === 2">をお気に入りに追加しました。</span>
                </p>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <showAll />
    </section>
  </div>
</template>

<script>
import Head from "@/components/block/Head.vue";
import showAll from "@/components/parts/showAll.vue";
import { mapGetters } from "vuex"
import dayjs from 'dayjs'

export default {
  data() {
    return {
      HeadSetting: {
        Left: '',
        Right: 'backBtn',
        Center: 'タイムライン'
      },
      title: 'タイムライン',
      description: 'ユーザータイムラインページ。TOKYOWAVE内でのお気に入りや閲覧などのアクションを、時系列で確認することができます。',
      timeline: {}
    };
  },
  components: {
    Head,
    showAll,
  },
  computed: {
    ...mapGetters([
      'userHistories'
    ]),
    getTimeline: function() {
      const today = dayjs().format('M月D日')
      const yesterday = dayjs().subtract(1, 'day').format('M月D日')

      for(let i in this.userHistories){
        let date = dayjs.unix(this.userHistories[i].time).format('M月D日')

        if(date === today) date = 'きょう'
        if(date === yesterday) date = 'きのう'

        if(this.timeline[date] === undefined){
          this.timeline[date] = []
        }

        if(this.userHistories[i].title !=='') this.timeline[date].push(this.userHistories[i])
      }

      return this.timeline;
    }
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
    meta: function () {
      return [
        { property: 'og:title', content: this.title, id: 'og-title'},
        { property: 'og:description', content: this.description, id: 'og-desc' },
        { name: 'description', content: this.description, id: 'meta-desc'},
      ]
    },
  },
}
</script>

<style scoped>
.container{
  background-color: #EEE;
}

.streamCog{
  padding: 0 12px;
  text-align: right;
  color: #AAA;
  font-size: 1.4rem;
  margin: 8px 0;
}

.stream__pr{
  font-size: .7rem;
  padding: 6px 8px;
  margin: 0;
  color: #666;
}

.streamMenuIpt:checked + .streamMenuList{
  max-height: 120px;
}

.streamMenuList{
  display: flex;
  justify-content: center;
  border-top: 1px solid #EEE;
  background-color: #1AB3FF;
  color: #FFF;
  font-size: .8rem;
  max-height: 0;
  transition: all 0.5s ease;
}

.streamMenuAction{
  display: block;
  padding: 12px 24px;
}

.streamMenuIpt{
  display:none
}

.streamMenu{
  display: flex;
  min-width: 30px;
  max-width: 30px;
  padding: 8px;
  height: 30px;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
}

.steamItemThumb{
  display: flex;
  min-width: 20%;
  max-width: 20%;
  height: 70px;
  align-items: center;
  overflow: hidden;
  padding: 2px;
  margin-bottom: 2px;
}

.steamItemThumb img{
  max-width: 100%;
}

.streamItem{
  margin: 24px 12px 0;
  padding-bottom: 12px
}

.streamItemInner{
  padding: 12px;
  border: 1px solid #EEE;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.streamItemImg{
  max-width: 100px;
  min-width: 100px;
  height: 140px;
  overflow: hidden;
  display: block;
  margin-right: 16px;
  align-items: center;
}

.streamItemImg img{
  max-width: 100%;
}

.itemPriceNow{
  margin-bottom: 8px;
}

.streamItemDetail{
  width: 100%;
}

.streamItemListBox{
  display: flex;
  flex-wrap: wrap;
}

.streamItemList{
  padding: 12px;
  margin-top: 24px;
}

.subName{
  display: block;
  font-size: .7rem;
  font-weight: normal;
  margin-top: 4px;
  color: #666;
}

.streamAttach{
  width: 100%;
  height: 120px;
  overflow: hidden;
  margin-bottom: 0;
  margin-top: 24px;
}

.streamAttach  img{
  max-width: 100%;
}

.streamCon{
  padding: 4px 12px;
  font-size: .85rem;
  margin: 0 0 8px;
}

.stream__user{
  display: flex;
  padding: 8px 10px;
}

.streamIcon{
  display: flex;
  width: 44px;
  height: 44px;
  align-items: center;
}

.streamHead{
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 12px;
}

.streamHeadName{
  font-size: .85rem;
  color: #999;
  font-weight: bold;
  width: 100%;
}

.streamHeadDate{
  font-size: .75rem;
  color: #AAA;
  min-width: 60px;
}

.streamIcon img{
  width: 100%;
}


.stream__box{
  display: block;
  background-color: #FFF;
  border-radius: 8px;
  overflow: hidden;
  border-bottom: 1px solid #DDD;
  position: relative;
  z-index: 10;
  margin: 24px 8px;
}


.stream__box--inner{
  position: relative;
}


.stream__line.light .stream__line--txt__inner{
  padding: 8px;
}

.stream__list{
  position:relative;
  margin: -6px 0;
}

.stream__line{
  margin: 16px 16px 16px 8px;
  display: flex;
  align-items: center;
  z-index: 10;
  position:relative;
}

.stream__list:before{
  content: '';
  height: calc(100% - 60px);
  width: 4px;
  display: block;
  background-color: #afdcf8;
  position: absolute;
  left: 36px;
  top: 30px;
  z-index: 1;
}

.stream__line--icon{
  padding: 8px;
  margin: 0;
}

.stream__line--icon--img{
  color: #FFF;
  width: 44px;
  height: 44px;
  border: 4px solid #afdcf8;
  font-size: 1.4rem;
  background-color: #1AB3FF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.stream__line.light .stream__line--icon{
  padding: 0 14px;
}


.stream__line.light .stream__line--icon--img{
  background-color: #CCC;
  border: 4px solid #DDD;
  width: 32px;
  height: 32px;
  font-size: 1rem;
}

.stream__line--txt{
  font-size: .8rem;
  line-height: 1.2rem;
  display: flex;
  align-items: center;
  margin-left: -8px;
}

.stream__line.light .stream__line--txt{
  font-size: .65rem;
  line-height: .8rem;
}

.stream__line--txt a{
  color: #1AB3FF;
  font-weight: bold;
}

.stream__line.light .stream__line--txt a{
  font-weight: normal;
}

.stream__line--txt:before{
  content: '';
  border-top: 8px solid transparent;
  border-right: 8px solid #FFF;
  border-bottom: 8px solid transparent;
  border-left: 8px solid transparent;
}

.stream__line--txt__inner{
  background-color: #FFF;
  padding: 12px;
  border-radius: 6px;
  margin: 0;
}

.stream__date{
  font-size: .85rem;
  font-weight: bold;
  color: #999;
  text-align: center;
}

</style>
