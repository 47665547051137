<template functional>
  <router-link :to="props.route" class="moreBtn">
    <span class="moreBtnIcon bounce-top"><font-awesome-icon icon="chevron-right" /></span>
    <span class="moreBtnTxt"><slot /></span>
  </router-link>
</template>

<script>
export default {
  props: ['route'],
}
</script>

<style scoped>
.moreBtn{
  display: block;
  text-align: center;
  padding: 12px;
  margin: 12px auto;
  position: relative;
}

.moreBtnIcon{
  animation: bounce-arrow 2s infinite;
  display: block;
}

.moreBtnIcon svg{
  display: inline-block;
  transform: rotate(90deg);
}

.moreBtnTxt{
  display: block;
  font-size: .75rem;
}

.bounce-top {
    -webkit-animation: bounce-top 1.5s ease 2s infinite;
            animation: bounce-top 1.5s ease 2s infinite;
}

@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-14px);
            transform: translateY(-14px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-14px);
            transform: translateY(-14px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-14px);
            transform: translateY(-14px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-14px);
            transform: translateY(-14px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
</style>
