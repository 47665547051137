<template>
  <span class="item__list__box--inner">
    <span class="item__list__box__img" v-html="imgUrl" v-lazy-container="{ selector: 'img', error: '/img/noimage.png' }"></span>
    <span class="item__list--detail">
      <span class="itemListBrand">{{item.brand_name_en}}</span>
      <span class="itemListPrice">{{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(item.min_price)}}</span>
    </span>
    <span v-show="item.max_offrate != '0'" class="item__list--label">{{Math.round(Number(item.max_offrate * 100))}}%OFF</span>
  </span>
</template>

<script>
import axios from 'axios';
// import lazysizes from 'lazysizes'
import {Mixin} from '@/mixins/common'

export default {
  name: 'ItemListBox',
  props: ['item'],
  data() {
    return {
      imgUrl: '',
    }
  },
  mixins:[Mixin],
  created () {
    // console.log('item-id ' + this.item.id + 'のコンポーネント作成');
    this.getImg(this.item.id)
  },
  mounted () {
    // console.log('item-id ' + this.item.id + 'の画像を取得します');
    // this.getImg(this.item.id)
  },
  methods: {
    getImg: async function(id){
      let url = this.api + '/items/' + id + '/imgs.json'

      await axios.get(url)
      .then((res) => {
        // this.imgUrl = res.data.mainImg
        this.imgUrl = res.data.S[1]
      })
      .catch(() =>{
        console.log('item img not found')
       // this.$router.push({ name: "404"})
      })
    },
  }
}
</script>

<style>
.item__list--label{
  position: absolute;
  top: 0;
  left: 0;
  font-size: .65rem;
  color: #FFF;
  padding: 2px 4px;
  background-color: #FF3300;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.item__list__box--inner{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.item__list--detail{
  width: 100%;
  font-size: .65rem;
  padding: 2px 8px 8px;
  display: block;
  position: absolute;
  bottom: 0;
  color: #FFF;
  text-shadow: 0px 1px 4px #666;
}

.itemListBrand{
  display: block;
  word-break : break-all;
  font-size: 12px;
  line-height: 12px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.itemListPrice{
  display: block;
  padding-top: 4px;
  font-size: .8rem;
  line-height: .85rem;
}

.item__list__box__img{
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.item__list__box__img:after{
  width: 100%;
  height: 100%;
  position: absolute;
  content: '';
  display: block;
  top: 0;
  right: 0;
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(51,51,51,0) 35%, rgba(51,51,51,0.2) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(51,51,51,0) 35%,rgba(51,51,51,0.2) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(51,51,51,0) 35%,rgba(51,51,51,0.2) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#33333333',GradientType=0 );
}


.item__list__box__img img.lazyload[lazy=loading]{
  display:block;
  position: relative;
}

.item__list__box__img img.lazyload[lazy=loaded]{
  width: 100%;
}
</style>
