<template>
  <div class="gheadBox">
    <nav class="ghead" id="ghead" v-bind:class="{focused: searchOpen, opacity: data.Opacity}">
      <p class="btnBox" v-if="data.Left" @click="searchOff">
        <a v-if="data.Left==='backBtn' && hasHistory" @click="pageBack();searchOff();closeSOption();" class="btn">
          <span class="btn-inner"><font-awesome-icon icon="arrow-left" /></span>
          <span class="hide">戻る</span>
        </a>
        <router-link v-else-if="data.Left==='backBtn' && !hasHistory" :to="{name: 'home'}" class="btn">
          <span class="btn-inner"><font-awesome-icon icon="home" /></span>
          <span class="hide">ホーム</span>
        </router-link>
        <router-link v-else-if="data.Left==='board'" :to="{name: 'board'}" class="btn"><span class="btn-inner"><font-awesome-icon icon="stream" /></span>
        <span class="hide">マイボード</span>
        </router-link>
        <router-link :to="data.Left" v-else class="btn"><span class="btn-inner"><font-awesome-icon icon="arrow-left" /></span>
        <span class="hide">戻る</span>
        </router-link>
      </p>
      <p class="btnBox" v-else><span class="btn empty"></span></p>
      <div v-if="data.Center === 'search'" class="gheadSearch">
        <form @submit.prevent="doSearch" class="gsrch">
          <div class="gsrch-txtbox">
            <label class="hide" for="gsrch-txt">検索</label>
            <input autocomplete="off" @focus="searchOn();focusWord()" type="text" name="key" placeholder="ブランド名や商品名で探す" class="gsrch-txt" id="gsrch-txt" v-model="keyword" ref="gsrch-txt" aria-label="ブランド名や商品名で探す">
            <span class="gsrch-txt-del" v-if="searchWord !== ''" @click="clearWord"><font-awesome-icon icon="times-circle" /></span>
          </div>
          <span class="gsrchCancel" @click="searchOff();setKeywordDisplay()">キャンセル</span>
        </form>
      </div>
      <p v-else class="gnavTitle">{{data.Center}}</p>
      <p class="btnBox" v-if="data.Right" @click="searchOff">
        <a v-if="data.Right==='backBtn' && hasHistory" @click="pageBack()" class="btn reverse">
          <span class="btn-inner"><font-awesome-icon icon="arrow-left" /></span>
          <span class="hide">戻る</span>
        </a>
        <router-link v-else-if="data.Right==='backBtn' && !hasHistory" :to="{name: 'home'}" class="btn">
          <span class="btn-inner"><font-awesome-icon icon="home" /></span>
          <span class="hide">ホーム</span>
        </router-link>
        <span v-else-if="data.Right === 'Share'" class="btn"><span class="btn-inner"  @click="webShare(getshareData)"><font-awesome-icon icon="share-alt" /></span><span class="hide">シェアする</span></span>
        <router-link v-else-if="data.Right==='mypage'" :to="{name: 'mypage'}" class="btn"><span class="btn-inner"><font-awesome-icon icon="user" /></span><span class="hide">マイページ</span></router-link>
        <router-link v-else-if="data.Right==='home'" :to="{name: 'home'}" class="btn"><span class="btn-inner"><font-awesome-icon icon="arrow-right" /><span class="hide">ホーム</span></span></router-link>
        <label for="soinput" v-if="data.Right === 'searchOption'" class="btn" :class="{active: optionOn, noticeBtn:optionOn}" ><span class="btn-inner"><font-awesome-icon icon="sliders-h" /></span><span class="hide">検索オプション</span></label>
      </p>
      <p class="btnBox" v-else><span class="btn empty"></span></p>
    </nav>
    <div class="wordList">
      <Search />
      <span class="closeIcon"></span>
      <span class="hide">閉じる</span>
    </div>
    <input class="hide" type="checkbox" id="soinput" ref="soinput"/>
    <label class="searchOptionBk" for="soinput"></label>
    <div class="searchOption">
      <div class="searchOptionInner">
        <div class="sobox">
          <p class="soTitle">検索オプション</p>
        </div>
        <div class="sobox">
          <label class="soboxTitle">除外キーワード</label>
          <input type="text" name="ex-word" v-model="ex_word" class="soboxipt" aria-label="ex-word" />
        </div>
        <div class="sobox">
          <label class="soboxTitle">ブランド</label>
          <div v-show="brandLabel" class="so--brand__label" :class="{active: setBrand}">
            <div class="name">{{brandLabel}}</div>
            <label for="brand" class="btn"><font-awesome-icon icon="times-circle" /></label>
          </div>
          <input type="checkbox" class="hide" name="brand" id="brand" v-model="setBrand" />
        </div>
        <div class="sobox">
          <label class="soboxTitle">カテゴリ</label>
          <div class="soselect">
            <select name="sort" v-model="selectedCategory" aria-label="カテゴリを選択する">
              <option value="">---</option>
              <option v-for="(cate, key) in categories" :value="key">{{cate.name}}</option>
            </select>
          </div>
        </div>
        <div class="sobox">
          <label class="soboxTitle">価格</label>
          <p class="sobox__price-box">
            <input class="soboxipt" type="number" name="price-min" placeholder="1" v-model="price_min" aria-label="最低価格を入力する" />
            <span class="sobox__price-box__range">~</span>
            <input class="soboxipt" type="number" name="price-max" placeholder="999,999" v-model="price_max" aria-label="最高価格を入力する" />
          </p>
        </div>
        <div class="sobox">
          <label class="soboxTitle">アイテムタイプ</label>
          <div class="soselect">
            <select name="itemtype" v-model="itemtype" aria-label="アイテムタイプを選択する">
              <option value="all">すべて</option>
              <option value="sale">セールアイテム</option>
              <option value="new">新着アイテム</option>
            </select>
          </div>
        </div>
        <div class="sobox">
          <label class="soboxTitle">並び替え</label>
          <div class="soselect">
            <select name="sort" v-model="sort" aria-label="並び順を選択する">
              <option value="new">新着</option>
              <option value="price-low">価格が安い</option>
              <option value="price-high">価格が高い</option>
            </select>
          </div>
        </div>
        <div class="btmBtnBox">
          <div class="btmBtn" @click="clearOptionParams">クリア</div>
          <div class="btmBtn exec" @click="listResearch">完了</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Mixin} from '@/mixins/common'
import { mapGetters, mapActions } from 'vuex';
import Search from "@/components/block/Search.vue";

export default {
  props: ['data'],
  data() {
    return {
      keywordDisplay: '',
      sort: 'new',
      price_min: '',
      price_max: '',
      ex_word: '',
      itemtype: 'all',
      selectedCategory: '',
      setBrand: false,
      brandLabel: '',
      optionOn: false
    };
  },
  computed: {
    ...mapGetters([
      'searchOpen', 'getshareData', 'searchWord'
    ]),
    keyword: {
      get () {
        return this.keywordDisplay
      },
      set (value) {
        this.keywordDisplay = value;
        this.setSearchWord(value)
      }
    },
  },
  components: {
    Search,
  },
  created(){
    // console.log('head created')
    this.clearOptionParams()
  },
  mounted () {
    this.setOptionParams(this.$route)
  },
  watch: {
    $route(to, from) {
      // console.log('head watch');
      this.clearOptionParams()
      this.setOptionParams(to)
    }
  },
  mixins:[Mixin],
  methods: {
    ...mapActions([
      "setSearchWord"
    ]),
    clearOptionParams(){
      this.sort = 'new'
      this.price_min = ''
      this.price_max = ''
      this.ex_word = ''
      this.selectedCategory = ""
      this.itemtype = 'all'
      this.setBrand = false
      this.brandLabel = ''
      this.optionOn = false
    },
    setOptionParams(route){
      const routeQuery = Object.assign({}, this.$route.query);
      for(let [key, val] of Object.entries(routeQuery)){
        this[key] = decodeURI(val)
      }
      if(this.$route.name.match(/ategory/g)){
        if(route.params.code){
          this.selectedCategory = route.params.code
        }
        if(route.params.category){
          this.selectedCategory = route.params.category
        }
      }
      if(this.$route.name.match(/BrandItems/g)){
        this.setBrand = true
        this.brandLabel = route.params.code
      }


      // 絞り込みパラムス
      if(this.sort !== 'new' || this.price_min !== '' || this.price_max != '' || this.ex_word !== '' || this.itemtype !== 'all' || this.selectedCategory !== '' || this.setBrand !== false){
        this.optionOn = true
      }
      this.setKeywordDisplay()
    },
    setKeywordDisplay(){
      // keywordDisplay
      //keyword
      let keywordArray = []

      if(this.searchWord){
        keywordArray.push(decodeURI(this.$store.state.searchWord))
      }

      //brand
      if(this.brandLabel !==''){
        keywordArray.push(this.brandLabel)
      }

      // category
      if(this.selectedCategory !==''){
        keywordArray.push(this.categories[this.selectedCategory].name)
      }
      this.keywordDisplay = keywordArray.join()
    },
    focusWord(){
      if(this.$store.state.searchWord){
        this.keywordDisplay = decodeURI(this.$store.state.searchWord)
      }else{
        this.keywordDisplay = '';
      }
    },
    doSearch(){
      let routeObj = {}

      // // 検索履歴オブジェクト
      const swhistory = {}
      // 価格
      if(this.price_min !== '' || this.price_max != ''){
        swhistory.price_min = this.price_min
        swhistory.price_max = this.price_max
      }
      // 並び替え
      if(this.sort !== 'new'){
        swhistory.sort = this.sort
      }
      // 除外ワード
      if(this.ex_word !== ''){
        swhistory.ex_word = this.ex_word
      }
      // アイテムタイプ
      if(this.itemtype !== 'all'){
        swhistory.itemtype = this.itemtype
      }

      // console.log(swhistory);

      // console.log(this.keywordDisplay);
      this.searchOff()
      // カテゴリ対応
      if(this.selectedCategory !== ''){
        if(this.brandLabel !==''){
          routeObj.name = 'BrandItemsCategory'
          routeObj.params = {code: this.brandLabel, category: this.selectedCategory}
        }else{
          routeObj.name = 'CategoryListSearch'
          routeObj.params = {code: this.selectedCategory}
        }
      }else{
        if(this.brandLabel !==''){
          routeObj.name = 'BrandItems'
          routeObj.params = {code: this.brandLabel}
        }else{
          routeObj.name = 'Search'
        }
      }

      routeObj.query = swhistory

      if(this.keywordDisplay !== ''){
        // console.log(this.keywordDisplay);
        // console.log('キーワードをセット');
        routeObj.query.key = this.keywordDisplay
        this.setSearchWord(this.keywordDisplay)
      }
      // console.log(routeObj);

      this.$router.push(routeObj)
    },
    clearWord(){
      // console.log('word clear');
      this.$store.commit('setSearchWord', '')
      this.$refs["gsrch-txt"].focus()
    },
    listResearch() {
      const routeParams = Object.assign({}, this.$route);

      //build push object
      let routeObj = {}
      routeObj.name = routeParams.name
      if(routeParams.params){
        routeObj.params = routeParams.params
      }

      // query params build
      routeObj.query = {}
      const queryList = ['price_min', 'price_max', 'sort', 'ex_word', 'itemtype']
      for(let querykey of queryList){
        if(this[querykey] !== ''){
          // itemtype allはなしと同じだから消す
          if(querykey === 'itemtype'){
            if(this[querykey] !== 'all'){
              routeObj.query[querykey] = encodeURI(this[querykey])
            }
          // sort newはなしと同じだから消す
          }else if(querykey === 'sort'){
            if(this[querykey] !== 'new'){
              routeObj.query[querykey] = encodeURI(this[querykey])
            }
          }else{
            routeObj.query[querykey] = encodeURI(this[querykey])
          }
        }
      }

      // キーワード
      if(this.keyword){
        routeObj.query['key'] = encodeURI(this.searchWord)
      }

      // カテゴリ対応
      if(this.selectedCategory !== ''){
        routeObj.name = 'CategoryListSearch'
        routeObj.params = {code: this.selectedCategory}
      }else{
        routeObj.name = 'Search'
      }

      // ブランド商品一覧
      if(routeParams.name.match(/BrandItems/g)){
        routeObj.name = routeParams.name
        routeObj.params = routeParams.params

        // オプションでブランドが解除されたら
        if(this.setBrand === false){
          // カテゴリが設定されているか
          if(this.selectedCategory !== ''){
            routeObj.name = 'CategoryListSearch'
            routeObj.params.code = this.selectedCategory
          }else{
            routeObj.name = 'Search'
          }
        // ブランドカテゴリページでカテゴリが除外された時
        }else if(routeObj.params.category !== this.selectedCategory){
          // カテゴリが削除された時の対策
          if(this.selectedCategory === ''){
            routeObj.name = 'BrandItems'
          }else{
            // カテゴリが絞られてたら、問答無用でルートチェンジ
            routeObj.name = 'BrandItemsCategory'
            routeObj.params.category = this.selectedCategory
          }
        }

      }

      // console.log(routeObj);
      this.closeSOption()
      this.$router.push(routeObj)
    },
    closeSOption(){
      this.$refs["soinput"].checked = false
    }
  }
}
</script>

<style scoped>
.so--brand__label{
  display: flex;
  align-items: center;
  font-size: .8rem;
  color: #FFF;
  background-color: #DDD;
  border-radius: 6px;
  padding: 4px 6px;
}

.so--brand__label .name{
  padding-right: 8px;
}

.so--brand__label.active{
  background-color: #00aaff;
}

.so--brand__label .btn{
  display: inline-block;
  transform: rotate(45deg);
  color: #00aaff;
  margin: 0;
  transition: all 0.4s ease;
}

.so--brand__label.active .btn{
  transform: rotate(0);
  color: #FFF;
}

.sobox__price-box{
  display: flex;
  align-items: center;
}

.sobox__price-box__range{
  padding: 0px 6px;
}

.soselect {
	overflow: hidden;
	width: 90%;
	margin: 0 auto;
	text-align: center;
}
.soselect select {
	width: 100%;
	padding-right: 1em;
	cursor: pointer;
	text-indent: 0.01px;
	text-overflow: ellipsis;
	border: none;
	outline: none;
	background: transparent;
	background-image: none;
	box-shadow: none;
	-webkit-appearance: none;
	appearance: none;
}
.soselect select::-ms-expand {
    display: none;
}

.soselect {
	position: relative;
	border: 1px solid #DDD;
	border-radius: 3px;
	background: #FFF;
}
.soselect::before {
	position: absolute;
	top: 0.8em;
	right: 0.9em;
	width: 0;
	height: 0;
	padding: 0;
	content: '';
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid #666666;
	pointer-events: none;
}
.soselect select {
	padding: 8px 38px 8px 8px;
	color: #666666;
}

.soTitle{
  margin: 0;
  font-size: .85rem;
  font-weight: bold;
  color: #999;
}
.soboxTitle{
  font-size: .8rem;
  max-width: 120px;
  min-width: 120px;
  padding-right: 12px;
}

.soboxipt{
  border: 1px solid #DDD;
  border-radius: 3px;
  width: 100%;
  line-height: 1.3rem;
  margin: 4px 0;
  padding: 2px 4px;
}

.sobox{
  display: flex;
  padding: 12px 16px;
  align-items: center;
}

.btmBtnBox{
  position: fixed;
  bottom: 0;
  right: -200%;
  width: 340px;
  display: table;
  table-layout: fixed;
  border-top: 1px solid #F9F9F9;
  border-bottom: 1px solid #F9F9F9;
  background-color: #FFF;
  transition: all 0.4s ease;
}

.btmBtn{
  padding: 12px 36px;
  font-size: .85rem;
  min-width: 120px;
  margin: 0;
  display: table-cell;
  text-align: center;
}

.btmBtn:active{
  background-color: #EEE;
}

.btmBtn.exec{
  color: #00aaff;
  font-weight: bold;
}

#soinput:checked ~ .searchOption .searchOptionInner .btmBtnBox{
  right: 0;
}


@keyframes show{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

#soinput:checked + .searchOptionBk{
  display: block;
  opacity: 1;
}

#soinput:checked ~ .searchOption{
  right: 0;
}

.searchOptionBk{
  display: none;
  height: 100vh;
  width: 100%;
  background-color: rgba(0,0,0,.6);
  top: 0;
  right: 0;
  position: fixed;
  animation: show .5s linear 0s;
  right: 0;
  opacity: .4;
}

.searchOptionInner{
  position: relative;
  width: 100%;
  height: 100vh;
}

.searchOption{
  height: 100vh;
  background-color: #FFF;
  width: 340px;
  position: fixed;
  right: -200%;
  transition: all 0.4s ease;
  padding-top: 46px;
}

.headSearch{
  width: 100%;
  text-align: center;
}

.gnavTitle{
  font-size: .85rem;
  font-weight: bold;
  margin: 0;
  line-height: 1.22rem;
  display: inline-block;
  width: 100%;
  height: 20px;
  text-align: center;
}

.btn{
  margin: 0 12px;
  display: flex;
  position: relative;
  z-index: 0;
  color: #333;
}


.btn.active{
  color: #00aaff;
}


.btn.empty{
  width: 28px;
}

.btn.reverse{
  transform: rotate(180deg)
}

.noticeBtn:after{
  content: '';
  /* display: block; */
  width: 8px;
  height: 8px;
  background-color: #FF3300;
  border-radius: 50%;
  position: absolute;
  right: -4px;
  z-index: 999;
}

.gheadSearch{
  width: 100%;
}

.gsrch{
  width: 100%;
  display: flex;
  align-items: center;
}

.gsrch-txtbox{
  width: 100%;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  position: relative;
}

.gsrch-txt{
  width: 100%;
  background-color: #EEE;
  border: 0;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  color: #999;
  transition: all 1s ease;
}

.opacity .gsrch-txt{
  opacity: .2;
}

.focused.opacity .gsrch-txt{
  opacity: 1;
}



.gsrch-txt:focus{
  color: #333
}

.gsrch-txt-del{
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: .8rem;
  color: #999;
}

.gsrchCancel{
  font-size: .7rem;
  width: 86px;
  padding: 8px;
  padding-right: 0;
  display: none;
}

.ghead.focused .gsrchCancel{
  display: block;
}

.wordList{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: -110vh;
  left: 0;
  background-color: #F7F7F7;
  z-inde: -1;
  transition: all 0.4s ease;
  border-bottom: 1px solid #CCC;
}

.closeIcon{
  position: fixed;
  bottom: 10px;
  text-align: center;
  width: 100%;
  height: 10px;
  display: none;
  justify-content: center;
}

.closeIcon:after{
  content: '';
  background-color: #DDD;
  height: 3px;
  width: 30px;
  border-radius: 12px;
}

.ghead.focused ~ .wordList{
  top: 46px;
}

.ghead.focused ~ .wordList .closeIcon{
  display: flex;
}

</style>
