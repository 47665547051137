<template>
  <div class="img__box">
    <input type="checkbox" class="hide" id="cmpbtn"/>
    <label class="img__box--pc--comp__btn comp" for="cmpbtn"><span class="btnicon"><font-awesome-icon icon="compress-arrows-alt" /></span></label>
    <label class="img__box--pc--comp__btn expa" for="cmpbtn"><span class="btnicon"><font-awesome-icon icon="expand-arrows-alt" /></span></label>
    <div class="img__box--inner">
      <input v-for="(imgs, index) in lists.L" class="hide" type="radio" name="tab" :id="'tab' + index" :value="index" v-model="now" ref="tabIpt" />
      <ul class="img__box__lists" v-touch:swipe.left="swipeLeftHandler" v-touch:swipe.right="swipeRightHandler">
        <li v-for="(imgs, index) in lists.L" :key="index">
          <p class="img__box__lists--box" v-html="imgs" v-lazy-container="{ selector: 'img'}"></p>
        </li>
      </ul>
      <!-- <span v-scroll-to="{el: '#imgDetail', container: 'body', duration: 300, easing: 'linear', offset: -96, force: true, cancelable: true, onStart: false, onDone: false, onCancel: false, x: false, y: true}" class="imgDetailBtn"><span class="icon"><font-awesome-icon icon="th" /></span>詳細画像</span> -->
      <div class="arrow__box">
        <label v-if="getPrev" class="arrow left" :for="'tab' + getPrev"><span class="icon"><font-awesome-icon icon="chevron-right" /></span></label>
        <label v-if="getNext" class="arrow right" :for="'tab' + getNext"><span class="icon"><font-awesome-icon icon="chevron-right" /></span></label>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['lists'],
  data() {
    return {
      now: 1,
    }
  },
  created () {
    // console.log('headimg created');
    this.now = 1
  },
  watch: {
    $route(to, from) {
      // console.log('headimg watching');
      this.now = 1
    }
  },
  computed: {
    getNow: function() {
      return parseInt(this.now)
    },
    getNext: function() {
      const tmp = parseInt(this.now) + 1
      // console.log('next' + tmp);
      if(this.lists.count >= tmp){
        return tmp
      }else{
        return false;
      }
    },
    getPrev: function() {

      const tmp = parseInt(this.now) - 1
      // console.log('prev' + tmp);
      if(tmp > 0){
        return tmp
      }else{
        return false;
      }
    },
  },
  methods: {
    swipeRightHandler(){
      // console.log('swipe left');
      const num = parseInt(this.getPrev) - 1
      if(this.$refs["tabIpt"][num]){
        this.now = this.getPrev
        this.$refs["tabIpt"][num].checked = true
      }
    },
    swipeLeftHandler(){
      // console.log('swipe right');
      const num = parseInt(this.getNext) - 1
      if(this.$refs["tabIpt"][num]){
        this.now = this.getNext
        this.$refs["tabIpt"][num].checked = true
      }
    },
    testMethod(){
      this.now = 2
    }
  }
}
</script>


<style>

#tab1:checked ~ .img__box__lists{
  transform: translateX(0);
}
#tab2:checked ~ .img__box__lists{
  transform: translateX(-100vw);
}
#tab3:checked ~ .img__box__lists{
  transform: translateX(-200vw);
}
#tab4:checked ~ .img__box__lists{
  transform: translateX(-300vw);
}
#tab5:checked ~ .img__box__lists{
  transform: translateX(-400vw);
}
#tab6:checked ~ .img__box__lists{
  transform: translateX(-500vw);
}
#tab7:checked ~ .img__box__lists{
  transform: translateX(-600vw);
}
#tab8:checked ~ .img__box__lists{
  transform: translateX(-700vw);
}
#tab9:checked ~ .img__box__lists{
  transform: translateX(-800vw);
}
#tab10:checked ~ .img__box__lists{
  transform: translateX(-900vw);
}


.img__box{
  width: 100%;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
}

.img__box--inner{
  width: 100vw;
  height: 460px;
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
  position: relative;
}

.img__box__lists{
  transform: translateX(0);
  list-style: none;
  padding-left: 0;
  height: 100%;
  margin: 0;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0;
  transition: all 0.3s ease;
  vertical-align: top;
}

.img__box__lists li{
  width: 100vw;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  background-color: #FFF;
}


.img__box__lists--box{
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}


.img__box__lists--box a{
  display: block;
}

.img__box__lists li:after{
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.img__box__lists li img.lazyload[lazy=loaded]{
  height: 100%;
  /* width: 100%; */
}

.img__box__lists li img.lazyload[lazy=loading]{
  display:block;
  position: relative;
  width: auto;
}

.img__box--inner .arrow{
  width: 48px;
  height: 48px;
  position: absolute;
  top: 400px;
  font-size: 1.2rem;
  padding: 12px;
  margin: 0;
  border-radius: 50%;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,.2);
  z-index: 9990;
}

.img__box--inner .arrow:active{
  background-color: rgba(0,0,0,.2)
}

.img__box--inner .arrow.left{
  left: 0;
}

.img__box--inner .arrow.left .icon svg{
  display: inline-block;
  transform: rotate(180deg);
}

.img__box--inner .arrow.right{
  right: 0;
}

.img__box--pc--comp__btn{
  position: absolute;
  top: 40px;
  width: 44px;
  height: 44px;
  left: 4px;
  padding: 12px;
  font-size: 1.4rem;
  z-index: 999;
  background-color: rgba(255,255,255,.4);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.img__box--pc--comp__btn.expa{
  display: none;
}

#cmpbtn:checked ~ .img__box--inner .img__box__lists--box a{
  height: 100%;
}

#cmpbtn:checked ~ .img__box--inner .img__box__lists--box a img{
  width: auto;
}

#cmpbtn:checked ~ .img__box--pc--comp__btn.comp{
  display: none;
}

#cmpbtn:checked ~ .img__box--pc--comp__btn.expa{
  display: block;
}

@media (min-width:980px) {
  .img__box--inner{
    height: 100vh;
  }

  .img__box--inner .arrow{
    top: auto;
    bottom: 20px;
  }

  .arrow__box{
    width: 140px;
    display: inline-block;
    position: relative;
    margin-bottom: 24px;
  }

}
</style>
