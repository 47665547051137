<template functional>
  <ul class="pageMenus" v-bind:id="props.pageNav.id">
    <li v-for="page in props.pageNav.lists" v-bind:key="page.name">
      <router-link :to="page.route" class="pageMenusLink" v-scroll-to="{el: page.scrollTo ,container: 'body',duration: 300,easing: 'linear',offset: -46,force: true,x: false,y: true}" v-bind:exact="page.exact">
        <span class="pageMenusTxt">{{page.title}}</span>
      </router-link>
    </li>
  </ul>
</template>
<script>
export default {
  props: ['pageNav'],
  data() {
    return {
    }
  },
  watch: {
  },
}
</script>

<style>
.pageMenus{
  display: flex;
  list-style: none;
  padding-left: 0;
  flex-wrap: wrap;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: #FFF;
  padding: 0 8px;
  margin: 0 0 2px;
  box-shadow: 0px 2px 1px 0px #EEE;
}

.pageMenus li{
  min-width: 25%;
  max-width: 25%;
  text-align: center;
}

.pageMenusLink{
  text-decoration: none;
  color: #333;
  position: relative;
  padding: 8px;
  display: inline-block;
  width: 100%;
}

.pageMenusLink.router-link-active:after{
  content: '';
  height: 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-color: #FF3300;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.pageMenusTxt{
  font-size: .75rem;
}


</style>
